@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.button {
  position: relative;
  display: inline-block;
  height: 3.75vw;
  min-width: 10.15625vw;
  padding: 1.171875vw 2.34375vw 0.78125vw;
  text-decoration: none;
  color: white;
  background: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
  opacity: 1;
  border-radius: 4.6875vw;
  transition: all 0.35s ease-in-out;
}
@media only screen and (min-width: 1281px) {
  .button {
    height: 48px;
    min-width: 130px;
    padding: 15px 30px 10px;
    border-radius: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .button {
    height: 11.2vw;
    min-width: 32vw;
    padding: 2.9333333333vw 8vw 2.6666666667vw;
    border-radius: 16vw;
  }
}
@media only screen and (min-width: 1025px) {
  .button:hover::after {
    opacity: 1;
    visibility: visible;
  }
}
.button:hover {
  cursor: pointer;
  opacity: 0.9;
}
.button.disabled {
  pointer-events: none;
  background-color: #b0c4cb !important;
}
.button.withIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button.withIcon.left .icon {
  margin: 1px -0.3125vw 0 0;
  transform: rotate(90deg);
}
.button.withIcon.right {
  flex-direction: row-reverse;
}
.button.withIcon.right .icon {
  margin: 0.15625vw 0 0 -0.3125vw;
  transform: rotate(-90deg);
}
.button.withIcon .icon {
  width: 1.40625vw;
  height: 1.40625vw;
}
.button.withIcon .icon svg {
  width: 100%;
  height: 100%;
}
.button .label {
  position: relative;
  font-family: "Assistant", sans-serif;
  font-weight: bold;
  font-size: 1.5625vw;
  line-height: 1;
  text-align: center;
}
@media only screen and (min-width: 1281px) {
  .button .label {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .button .label {
    font-size: 4.8vw;
    line-height: 1.2;
  }
}

.is-mobile {
  width: 40vw;
  height: 16vw;
}
.is-mobile.withIcon .icon {
  width: 4.8vw;
  height: 4.8vw;
}
.is-mobile .label {
  font-size: 8vw;
}