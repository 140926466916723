@charset "UTF-8";
/* stylelint-disable no-descending-specificity */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
@value transitionIn: 500ms;
@value transitionOut: 500ms;
.cover-page {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  min-height: 100vh;
}
.cover-page-content {
  padding-top: 27.578125vw;
}
@media only screen and (max-width: 767px) {
  .cover-page-content {
    padding-top: 69.3333333333vw;
  }
}
.cover-page-content::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  height: calc(27.578125vw + 52.03125vw);
  background-image: linear-gradient(to bottom, #0f0f0f 17%, rgba(24, 24, 24, 0.92) 92%);
}
@media only screen and (max-width: 767px) {
  .cover-page-content::after {
    height: 181.3333333333vw;
  }
}
.cover-page .container {
  padding: 0 1.5625vw;
}
@media only screen and (max-width: 767px) {
  .cover-page .container {
    padding: 0;
  }
}
.cover-page.appear, .cover-page.enter {
  opacity: 0;
}
.cover-page.appearing, .cover-page.entering {
  opacity: 1;
  transition: opacity;
  transition-duration: transitionIn;
}
.cover-page.exit {
  opacity: 1;
}
.cover-page.exiting {
  opacity: 0;
  transition: opacity;
  transition-duration: transitionOut;
}

.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 1.015625vw 0 2.890625vw;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, #141414 3%, #282828 97%);
}
@media only screen and (max-width: 767px) {
  .wrapper {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper.is-scrolling .slide .cover-wrap {
    width: 52.2666666667vw;
  }
  .wrapper.is-scrolling .slide .cover-image {
    border: 1.8666666667vw solid white;
  }
  .wrapper.is-scrolling .navigation .button-next,
  .wrapper.is-scrolling .navigation .button-prev {
    top: 30%;
    width: 17.0666666667vw;
    height: 17.0666666667vw;
  }
}
.wrapper .close-icon {
  position: absolute;
  top: 1.953125vw;
  right: 1.5625vw;
  z-index: 10;
  opacity: 1;
  transition: 0.2s;
}
.wrapper .close-icon:hover {
  cursor: pointer;
  opacity: 0.8;
}
.wrapper .slide-wrapper {
  display: flex;
  height: 100%;
  max-width: 96.875vw;
  padding: 0 5.9375vw;
  margin: 0 auto;
  align-items: center;
}
@media only screen and (min-width: 1281px) {
  .wrapper .slide-wrapper {
    max-width: fit-content;
    padding: 0 76px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .slide-wrapper {
    height: auto;
    max-width: 100%;
  }
}
.wrapper .slide {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 5.9375vw 0 0.78125vw 0;
  justify-content: space-between;
  color: white;
}
@media only screen and (min-width: 1281px) {
  .wrapper .slide {
    width: 100%;
    padding: 76px 0 10px 0;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .slide {
    width: 100%;
    max-height: 100%;
    padding: 15.4666666667vw 8vw 5.3333333333vw;
    flex-direction: column-reverse;
  }
}
.wrapper .slide .cover-wrap {
  position: relative;
  display: flex;
  height: 100%;
  max-width: 50%;
  max-height: 100%;
  justify-content: flex-end;
  align-items: self-end;
}
@media only screen and (min-width: 1281px) {
  .wrapper .slide .cover-wrap {
    width: auto;
    height: 100%;
    max-width: 47%;
    max-height: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .cover-wrap {
    width: 100%;
    height: 100%;
    max-width: 78.6666666667vw;
    margin: 0 auto;
    transition: 0.5s;
  }
}
.wrapper .slide .cover-wrap .cover-image {
  width: inherit;
  height: inherit;
  border: 1.171875vw solid white;
}
@media only screen and (min-width: 1281px) {
  .wrapper .slide .cover-wrap .cover-image {
    border: 15px solid white;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .cover-wrap .cover-image {
    border: 2.6666666667vw solid white;
  }
}
.wrapper .slide .cover-wrap img {
  width: auto;
  height: 100%;
  object-fit: contain;
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .cover-wrap img {
    width: 100%;
  }
}
.wrapper .slide .cover-wrap .credits {
  position: absolute;
  right: 1px;
  bottom: 0;
  font-size: 1.015625vw;
  color: black;
}
@media only screen and (min-width: 1281px) {
  .wrapper .slide .cover-wrap .credits {
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .cover-wrap .credits {
    font-size: 2.6666666667vw;
  }
}
.wrapper .slide .slide-content {
  display: flex;
  width: 30.46875vw;
  flex-direction: column;
  justify-content: flex-end;
}
@media only screen and (min-width: 1281px) {
  .wrapper .slide .slide-content {
    margin-left: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .slide-content {
    width: 100%;
    padding: 22px 10px 20px;
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .slide-content .top-text-wrapper {
    display: flex;
    padding-bottom: 22px;
    justify-content: space-between;
  }
}
.wrapper .slide .slide-content .slide-title {
  font-size: 3.4375vw;
  line-height: 1.2;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .slide-content .slide-title {
    font-size: 6.4vw;
  }
}
.wrapper .slide .slide-content .date {
  padding-bottom: 1.640625vw;
  font-size: 1.71875vw;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .slide-content .date {
    padding-bottom: 0;
    font-size: 6.4vw;
    line-height: 1.2;
    font-weight: normal;
  }
}
.wrapper .slide .slide-content .text-wrapper {
  height: 25vw;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .slide-content .text-wrapper {
    width: 100%;
    height: calc(100vh - 133.3333333333vw);
  }
}
.wrapper .slide .slide-content .text-wrapper .text {
  height: 100%;
  overflow-y: auto;
  font-size: 1.71875vw;
  font-weight: 600;
  line-height: 1.41;
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .slide-content .text-wrapper .text {
    font-size: 4.2666666667vw;
    font-weight: normal;
    line-height: 1.56;
    letter-spacing: 0.2px;
  }
}
.wrapper .slide .socials {
  height: auto;
  padding: 2.96875vw 4.609375vw 0;
}
@media only screen and (min-width: 1281px) {
  .wrapper .slide .socials {
    padding: 38px 59px 0;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .slide .socials {
    padding: 0 0 3.4666666667vw 1.8666666667vw;
    margin: 0 auto;
  }
}
.wrapper .navigation {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  display: flex;
  width: 26.5625vw;
  justify-content: space-between;
  transform: translate(-50%, 0);
  font-size: 1.71875vw;
  font-weight: 600;
  line-height: 1.41;
  color: white;
}
@media only screen and (min-width: 1281px) {
  .wrapper .navigation {
    top: 0;
    width: 340px;
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .navigation {
    position: static;
    width: 100%;
    transform: none;
  }
}
.wrapper .navigation .button-next,
.wrapper .navigation .button-prev {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .wrapper .navigation .button-next,
  .wrapper .navigation .button-prev {
    display: block;
  }
}
.wrapper .navigation .button-next .nav-label,
.wrapper .navigation .button-prev .nav-label {
  padding-bottom: 0.78125vw;
}
@media only screen and (min-width: 1281px) {
  .wrapper .navigation .button-next .nav-label,
  .wrapper .navigation .button-prev .nav-label {
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .navigation .button-next .nav-label,
  .wrapper .navigation .button-prev .nav-label {
    display: none;
  }
}
.wrapper .navigation .button-next .nav-label {
  margin-left: -25px;
}
.wrapper .navigation .button-prev .nav-label {
  margin-right: -25px;
}
.wrapper .navigation .button-next-arrow,
.wrapper .navigation .button-prev-arrow {
  z-index: 100;
  width: 8.59375vw;
  height: 8.59375vw;
  background-size: cover;
  opacity: 1;
  transition: 0.2s;
}
@media only screen and (min-width: 1025px) {
  .wrapper .navigation .button-next-arrow,
  .wrapper .navigation .button-prev-arrow {
    position: static;
  }
}
@media only screen and (min-width: 1281px) {
  .wrapper .navigation .button-next-arrow,
  .wrapper .navigation .button-prev-arrow {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper .navigation .button-next-arrow,
  .wrapper .navigation .button-prev-arrow {
    position: absolute;
    top: 43%;
    width: 17.0666666667vw;
    height: 17.0666666667vw;
    transform: translateY(-50%);
    transition: 0.5s;
  }
}
.wrapper .navigation .button-next-arrow:hover,
.wrapper .navigation .button-prev-arrow:hover {
  cursor: pointer;
  opacity: 0.8;
}
.wrapper .navigation .button-next-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='66px' height='66px' viewBox='0 0 66 66' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-1' x='0' y='0' width='30' height='30' rx='7.05882353'%3E%3C/rect%3E%3Cfilter x='-96.7%25' y='-83.3%25' width='293.3%25' height='293.3%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeMorphology radius='3' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'%3E%3C/feMorphology%3E%3CfeOffset dx='0' dy='4' in='shadowSpreadOuter1' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='6' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'%3E%3C/feComposite%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-2' transform='translate(18.000000, 14.000000)'%3E%3Cg id='Rectangle'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='rgba(0, 0, 0, 0.7)' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3Cpath d='M11.6483082,9.41176471 L12.9336376,10.6209442 L9.71258824,14.0427647 L23.6292225,14.1907732 L23.6105922,15.9553807 L9.80558824,15.8087647 L12.9230025,19.0101306 L11.6589433,20.2415286 L6.47058824,14.9155597 L11.6483082,9.41176471 Z' id='Combined-Shape' fill='%23FFFFFF' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}
@media only screen and (max-width: 767px) {
  .wrapper .navigation .button-next-arrow {
    left: -2px;
  }
}
.wrapper .navigation .button-prev-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='66' height='66' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cfilter x='-96.7%25' y='-83.3%25' width='293.3%25' height='293.3%25' filterUnits='objectBoundingBox' id='a'%3E%3CfeMorphology radius='3' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'/%3E%3CfeOffset dy='4' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur stdDeviation='6' in='shadowOffsetOuter1' result='shadowBlurOuter1'/%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' in='shadowBlurOuter1'/%3E%3C/filter%3E%3Crect id='b' x='0' y='0' width='30' height='30' rx='7.059'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='matrix(-1 0 0 1 48 14)' fill='rgba(0, 0, 0, 0.7)'%3E%3Cuse filter='url(%23a)' xlink:href='%23b'/%3E%3Cuse fill-opacity='1' xlink:href='%23b'/%3E%3C/g%3E%3Cpath d='m36.352 23.412-1.286 1.209 3.221 3.422-13.916.148.018 1.764 13.805-.146-3.117 3.201 1.264 1.232 5.188-5.326-5.177-5.504z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E ") !important;
}
@media only screen and (max-width: 767px) {
  .wrapper .navigation .button-prev-arrow {
    right: -2px;
  }
}