@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.celebs {
  position: relative;
  overflow: hidden;
  background-color: #f1f1f1;
}
.celebs-inner {
  position: relative;
  display: flex;
  height: 60.9375vw;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (min-width: 1281px) {
  .celebs-inner {
    width: 1280px;
    height: 780px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .celebs-inner {
    height: 100%;
    min-height: 177.8666666667vw;
  }
}
.celebs .bottomGradient {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  height: 18.515625vw;
  background-image: linear-gradient(to bottom, rgba(249, 249, 249, 0), #f1f1f1 50%);
}
@media only screen and (min-width: 1281px) {
  .celebs .bottomGradient {
    height: 237px;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .bottomGradient {
    height: 39.7333333333vw;
  }
}
.celebs .titleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.celebs .heart {
  width: 2.5vw;
  height: 2.03125vw;
  margin-left: 0.9375vw;
}
@media only screen and (min-width: 1281px) {
  .celebs .heart {
    width: 32px;
    height: 26px;
    margin-left: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .heart {
    position: relative;
    top: -1.0666666667vw;
    width: 5.3333333333vw;
    height: 4.2666666667vw;
    margin-left: 1.6vw;
  }
}
.celebs .coverImage {
  position: absolute;
  bottom: 21.875vw;
  z-index: 0;
  width: 21.71875vw;
  height: 29.609375vw;
  max-width: 100%;
  object-fit: contain;
  transform: rotate(-13deg);
}
@media only screen and (min-width: 1281px) {
  .celebs .coverImage {
    bottom: 280px;
    width: 278px;
    height: 379px;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .coverImage {
    bottom: 37.0666666667vw;
    left: -13.0666666667vw;
    width: 100%;
    height: 42.1333333333vw;
    max-width: 100%;
    max-height: 61.6vw;
  }
}
.celebs .celebImage {
  position: absolute;
  bottom: 4.6875vw;
  left: 17.109375vw;
  z-index: 1;
  width: 22.890625vw;
  object-fit: cover;
  object-position: top;
}
@media only screen and (min-width: 1281px) {
  .celebs .celebImage {
    bottom: 106px;
    left: 216px;
    width: 293px;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .celebImage {
    bottom: 16vw;
    left: 40vw;
    width: 39.4666666667vw;
  }
}
.celebs :global .enter :local .coverImage,
.celebs :global .enter :local .celebImage {
  opacity: 0;
}
.celebs :global .enter-active :local .coverImage {
  opacity: 1;
  transition: opacity 1000ms;
}
.celebs :global .enter-active :local .celebImage {
  opacity: 1;
  transition: opacity 700ms;
}
.celebs :global .exit :local .coverImage,
.celebs :global .exit :local .celebImage {
  opacity: 1;
}
.celebs .wrapper {
  position: relative;
  display: flex;
  height: 100%;
  margin-right: 1.5625vw;
  margin-left: 11.015625vw;
  flex-direction: row-reverse;
}
@media only screen and (min-width: 1281px) {
  .celebs .wrapper {
    margin-right: 20px;
    margin-left: 141px;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .wrapper {
    height: 100%;
    min-height: inherit;
    margin-right: 3.4666666667vw;
    margin-left: 4.2666666667vw;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
  }
}
.celebs .wrapper .photoCredit {
  position: absolute;
  top: 20px;
  right: calc(100% + 10.234375vw);
  font-size: 1.328125vw;
  color: #000;
  transform: rotate(-90deg) translateY(20px) translateZ(0);
  transform-origin: right top;
  white-space: nowrap;
}
.celebs .rightInnerWrapper {
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (max-width: 767px) {
  .celebs .rightInnerWrapper {
    display: flex;
    width: 100%;
    padding-top: 2.6666666667vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.celebs .rightInnerWrapper .celebs-top-content {
  padding-top: 2.421875vw;
}
@media only screen and (min-width: 1281px) {
  .celebs .rightInnerWrapper .celebs-top-content {
    padding-top: 31px;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .rightInnerWrapper .celebs-top-content {
    padding-top: 0;
  }
}
.celebs .rightInnerWrapper .topTitle {
  margin-bottom: 0.625vw;
  font-size: 2.65625vw;
  font-weight: bold;
  color: #292929;
}
@media only screen and (min-width: 1281px) {
  .celebs .rightInnerWrapper .topTitle {
    margin-bottom: 8px;
    font-size: 34px;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .rightInnerWrapper .topTitle {
    margin-bottom: 2.6666666667vw;
    font-size: 6.4vw;
  }
}
.celebs .rightInnerWrapper .name {
  font-weight: 700;
  font-size: 1.875vw;
  color: #292929;
}
@media only screen and (min-width: 1281px) {
  .celebs .rightInnerWrapper .name {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .rightInnerWrapper .name {
    margin-bottom: 2.1333333333vw;
    font-size: 4.8vw;
  }
}
.celebs .rightInnerWrapper .description {
  display: block;
  width: 39.0625vw;
  margin-bottom: 1.40625vw;
  color: #292929;
  font-size: 1.5625vw;
  line-height: 2.421875vw;
  text-align: right;
}
@media only screen and (min-width: 1281px) {
  .celebs .rightInnerWrapper .description {
    width: 500px;
    margin-bottom: 18px;
    font-size: 20px;
    line-height: 31px;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .rightInnerWrapper .description {
    width: 100%;
    margin-bottom: 2.9333333333vw;
    text-align: center;
    font-size: 4.2666666667vw;
    line-height: normal;
  }
}
.celebs .rightInnerWrapper .description b {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}
.celebs .rightInnerWrapper .description a {
  color: #dc2179;
  text-decoration: underline;
}
.celebs .rightInnerWrapper .description a span {
  color: #dc2179 !important;
  background-color: transparent !important;
}
.celebs .rightInnerWrapper .description span {
  background-color: transparent !important;
}
.celebs .rightInnerWrapper span {
  margin-bottom: 25vw !important;
  font-family: "Assistant", sans-serif !important;
  font-size: 1.5625vw !important;
}
@media only screen and (min-width: 1281px) {
  .celebs .rightInnerWrapper span {
    margin-bottom: 320px !important;
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .rightInnerWrapper span {
    margin-bottom: 0 !important;
    font-size: 4.2666666667vw !important;
  }
}
.celebs .rightInnerWrapper .titleWrapper {
  display: flex;
  margin-bottom: 1.640625vw;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (min-width: 1281px) {
  .celebs .rightInnerWrapper .titleWrapper {
    margin-bottom: 21px;
  }
}
@media only screen and (max-width: 767px) {
  .celebs .rightInnerWrapper .titleWrapper {
    margin-bottom: 0;
    justify-content: center;
  }
}
.celebs .rightInnerWrapper .content {
  color: #292929;
  font-size: 1.5625vw;
  line-height: 2.421875vw;
}
@media only screen and (min-width: 1281px) {
  .celebs .rightInnerWrapper .content {
    font-size: 20px;
    line-height: 31px;
  }
}