@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.catalog {
  position: relative;
  padding: 2.890625vw 1.5625vw 1.5625vw 1.5625vw;
}
@media only screen and (min-width: 1281px) {
  .catalog {
    max-width: 1280px;
    padding: 37px 20px 20px 20px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .catalog {
    padding: 6.6666666667vw 4vw 7.2vw;
  }
}
.catalog-title {
  padding-bottom: 2.96875vw;
  font-size: 2.65625vw;
  font-weight: bold;
}
@media only screen and (min-width: 1281px) {
  .catalog-title {
    padding-bottom: 38px;
    font-size: 34px;
  }
}
@media only screen and (max-width: 767px) {
  .catalog-title {
    padding-bottom: 6.4vw;
    font-size: 6.4vw;
    text-align: center;
  }
}
.catalog .search-and-datepicker {
  display: flex;
  padding: 0 0.234375vw;
  justify-content: space-between;
}
@media only screen and (min-width: 1281px) {
  .catalog .search-and-datepicker {
    padding: 0 3px;
  }
}
@media only screen and (max-width: 767px) {
  .catalog .search-and-datepicker {
    padding: 0 0.5333333333vw;
  }
}
.catalog .load-more {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 15.625vw;
  text-align: center;
}
@media only screen and (min-width: 1281px) {
  .catalog .load-more {
    height: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .catalog .load-more {
    height: 40vw;
  }
}
.catalog .load-more::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15.625vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(251, 251, 251, 0.83) 15%, rgba(250, 250, 250, 0.96) 18%, #fafafa 18%);
}
@media only screen and (min-width: 1281px) {
  .catalog .load-more::after {
    right: -50%;
    left: -50%;
    width: 1000%;
    height: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .catalog .load-more::after {
    height: 40vw;
  }
}
.catalog .load-more-button {
  position: absolute;
  bottom: 2.96875vw;
  left: 50%;
  z-index: 1;
  height: 3.90625vw;
  transform: translateX(-50%);
}
@media only screen and (min-width: 1281px) {
  .catalog .load-more-button {
    bottom: 38px;
    height: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .catalog .load-more-button {
    bottom: 9.6vw;
    height: 11.2vw;
  }
}
.catalog .popup-slider-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 1.015625vw 0 2.890625vw;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, #141414 3%, #282828 97%);
}
@media only screen and (max-width: 767px) {
  .catalog .popup-slider-wrapper {
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .catalog .popup-slider-wrapper.is-scrolling .popup-slide .cover-wrap {
    width: 52.2666666667vw;
    border: 1.8666666667vw solid white;
  }
  .catalog .popup-slider-wrapper.is-scrolling .popup-navigation .popup-button-next-arrow,
  .catalog .popup-slider-wrapper.is-scrolling .popup-navigation .popup-button-prev-arrow {
    top: 30%;
    width: 17.0666666667vw;
    height: 17.0666666667vw;
  }
}
.catalog .popup-slider-wrapper :global .swiper-container {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .catalog .popup-slider-wrapper :global .swiper-container {
    height: auto;
  }
}
.catalog .popup-slider-wrapper .close-icon {
  position: absolute;
  top: 1.953125vw;
  right: 1.5625vw;
  z-index: 10;
  opacity: 1;
  transition: 0.2s;
}
@media only screen and (min-width: 1281px) {
  .catalog .popup-slider-wrapper .close-icon {
    top: 25px;
    right: 20px;
  }
}
.catalog .popup-slider-wrapper .close-icon:hover {
  cursor: pointer;
  opacity: 0.8;
}
.catalog .popup-slider-wrapper .popup-navigation {
  position: absolute;
  top: 1.40625vw;
  left: 50%;
  z-index: 10;
  display: flex;
  width: 26.5625vw;
  justify-content: space-between;
  transform: translate(-50%, 0);
  font-size: 1.71875vw;
  font-weight: 600;
  line-height: 1.41;
  color: white;
}
@media only screen and (min-width: 1281px) {
  .catalog .popup-slider-wrapper .popup-navigation {
    top: 18px;
    width: 340px;
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .catalog .popup-slider-wrapper .popup-navigation {
    position: static;
    width: 100%;
    transform: none;
  }
}
.catalog .popup-slider-wrapper .popup-navigation .popup-button-next,
.catalog .popup-slider-wrapper .popup-navigation .popup-button-prev {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-next,
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-prev {
    display: block;
  }
}
.catalog .popup-slider-wrapper .popup-navigation .popup-button-next .nav-label,
.catalog .popup-slider-wrapper .popup-navigation .popup-button-prev .nav-label {
  padding-bottom: 0.78125vw;
}
@media only screen and (min-width: 1281px) {
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-next .nav-label,
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-prev .nav-label {
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-next .nav-label,
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-prev .nav-label {
    display: none;
  }
}
.catalog .popup-slider-wrapper .popup-navigation .popup-button-next .nav-label {
  margin-left: -25px;
}
.catalog .popup-slider-wrapper .popup-navigation .popup-button-prev .nav-label {
  margin-right: -25px;
}
.catalog .popup-slider-wrapper .popup-navigation .popup-button-next-arrow,
.catalog .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
  z-index: 100;
  width: 8.59375vw;
  height: 8.59375vw;
  background-size: cover;
  opacity: 1;
  transition: 0.2s;
}
@media only screen and (min-width: 1025px) {
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-next-arrow,
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
    position: static;
  }
}
@media only screen and (min-width: 1281px) {
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-next-arrow,
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (max-width: 767px) {
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-next-arrow,
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
    position: absolute;
    top: 43%;
    width: 17.0666666667vw;
    height: 17.0666666667vw;
    transform: translateY(-50%);
    transition: 0.5s;
  }
}
.catalog .popup-slider-wrapper .popup-navigation .popup-button-next-arrow:hover,
.catalog .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow:hover {
  cursor: pointer;
  opacity: 0.8;
}
.catalog .popup-slider-wrapper .popup-navigation .popup-button-next-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='66px' height='66px' viewBox='0 0 66 66' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-1' x='0' y='0' width='30' height='30' rx='7.05882353'%3E%3C/rect%3E%3Cfilter x='-96.7%25' y='-83.3%25' width='293.3%25' height='293.3%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeMorphology radius='3' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'%3E%3C/feMorphology%3E%3CfeOffset dx='0' dy='4' in='shadowSpreadOuter1' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='6' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'%3E%3C/feComposite%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-2' transform='translate(18.000000, 14.000000)'%3E%3Cg id='Rectangle'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='rgba(0, 0, 0, 0.7)' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3Cpath d='M11.6483082,9.41176471 L12.9336376,10.6209442 L9.71258824,14.0427647 L23.6292225,14.1907732 L23.6105922,15.9553807 L9.80558824,15.8087647 L12.9230025,19.0101306 L11.6589433,20.2415286 L6.47058824,14.9155597 L11.6483082,9.41176471 Z' id='Combined-Shape' fill='%23FFFFFF' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}
@media only screen and (max-width: 767px) {
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-next-arrow {
    left: -2px;
  }
}
.catalog .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='66' height='66' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cfilter x='-96.7%25' y='-83.3%25' width='293.3%25' height='293.3%25' filterUnits='objectBoundingBox' id='a'%3E%3CfeMorphology radius='3' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'/%3E%3CfeOffset dy='4' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur stdDeviation='6' in='shadowOffsetOuter1' result='shadowBlurOuter1'/%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' in='shadowBlurOuter1'/%3E%3C/filter%3E%3Crect id='b' x='0' y='0' width='30' height='30' rx='7.059'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='matrix(-1 0 0 1 48 14)' fill='rgba(0, 0, 0, 0.7)'%3E%3Cuse filter='url(%23a)' xlink:href='%23b'/%3E%3Cuse fill-opacity='1' xlink:href='%23b'/%3E%3C/g%3E%3Cpath d='m36.352 23.412-1.286 1.209 3.221 3.422-13.916.148.018 1.764 13.805-.146-3.117 3.201 1.264 1.232 5.188-5.326-5.177-5.504z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E ") !important;
}
@media only screen and (max-width: 767px) {
  .catalog .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
    right: -2px;
  }
}

.datepicker-button,
.tags-filter-button {
  position: relative;
  width: 15.78125vw;
  height: 5.78125vw;
  border-radius: 3.90625vw;
  transition: 0.2s;
}
@media only screen and (min-width: 1281px) {
  .datepicker-button,
  .tags-filter-button {
    width: 202px;
    height: 74px;
    border-radius: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-button,
  .tags-filter-button {
    width: 10.1333333333vw;
    height: 10.1333333333vw;
    border-radius: 13.3333333333vw;
  }
}
.datepicker-button::before,
.tags-filter-button::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -3px;
  border-radius: inherit;
  background: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
}
@media only screen and (max-width: 767px) {
  .datepicker-button::before,
  .tags-filter-button::before {
    margin: -2px;
  }
}
.datepicker-button:hover,
.tags-filter-button:hover {
  transform: scale(1.04);
}
.datepicker-button .button,
.tags-filter-button .button {
  position: relative;
  height: 5.78125vw;
  border-radius: 3.90625vw;
  background-color: white;
}
@media only screen and (min-width: 1281px) {
  .datepicker-button .button,
  .tags-filter-button .button {
    height: 74px;
    border-radius: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-button .button,
  .tags-filter-button .button {
    height: 10.1333333333vw;
    border-radius: 13.3333333333vw;
  }
}
.datepicker-button .button .calendar-icon-wrap,
.tags-filter-button .button .calendar-icon-wrap {
  position: absolute;
  top: 1.25vw;
  left: 1.328125vw;
  width: 3.4375vw;
  height: 3.4375vw;
  overflow: hidden;
}
@media only screen and (min-width: 1281px) {
  .datepicker-button .button .calendar-icon-wrap,
  .tags-filter-button .button .calendar-icon-wrap {
    top: 16px;
    left: 17px;
    width: 44px;
    height: 44px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-button .button .calendar-icon-wrap,
  .tags-filter-button .button .calendar-icon-wrap {
    top: 1.6vw;
    left: 1.6vw;
    width: 6.9333333333vw;
    height: 7.2vw;
  }
}
.datepicker-button .button .calendar-icon-wrap .calendar-icon,
.tags-filter-button .button .calendar-icon-wrap .calendar-icon {
  width: inherit;
  height: inherit;
  background-size: 166%;
  background-position: center;
}
.datepicker-button .button .year-holder,
.tags-filter-button .button .year-holder {
  position: absolute;
  top: 1.484375vw;
  right: 1.328125vw;
  color: #292929;
  font-size: 2.1875vw;
}
@media only screen and (min-width: 1281px) {
  .datepicker-button .button .year-holder,
  .tags-filter-button .button .year-holder {
    top: 19px;
    right: 17px;
    font-size: 28px;
  }
}
.datepicker-button:hover,
.tags-filter-button:hover {
  cursor: pointer;
}

.tags-filter .tags-icon {
  position: absolute;
  top: 1.25vw;
  left: 1.25vw;
  width: 3.90625vw;
  height: 3.828125vw;
  background-size: cover;
  background-position: center;
}
@media only screen and (min-width: 1281px) {
  .tags-filter .tags-icon {
    top: 16px;
    left: 16px;
    width: 49px;
    height: 49px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-filter .tags-icon {
    top: 1.6vw;
    left: 1.6vw;
    width: 6.9333333333vw;
    height: 7.2vw;
  }
}
.tags-filter .holder {
  position: absolute;
  top: 1.484375vw;
  right: 1.328125vw;
  color: #292929;
  font-size: 2.1875vw;
}
@media only screen and (min-width: 1281px) {
  .tags-filter .holder {
    top: 19px;
    right: 17px;
    font-size: 28px;
  }
}

.catalog-list {
  display: grid;
  margin-bottom: -9.21875vw;
  grid-template-columns: repeat(4, 23.28125vw);
  grid-template-rows: repeat(auto-fill, 30.859375vw);
  grid-row-gap: 1.40625vw;
  grid-column-gap: 1.25vw;
}
@media only screen and (min-width: 1281px) {
  .catalog-list {
    margin-bottom: -118px;
    grid-template-columns: repeat(4, 298px);
    grid-template-rows: repeat(auto-fill, 395px);
    grid-row-gap: 18px;
    grid-column-gap: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .catalog-list {
    margin-bottom: -5.0666666667vw;
    grid-template-columns: repeat(3, 29.6vw);
    grid-template-rows: repeat(auto-fill, 39.2vw);
    grid-column-gap: 1.6vw;
    grid-row-gap: 1.8666666667vw;
  }
}
.catalog-list.is-full-list {
  padding-bottom: 4.6875vw;
  margin-bottom: 0;
}
@media only screen and (min-width: 1281px) {
  .catalog-list.is-full-list {
    padding-bottom: 60px;
  }
}
.catalog-list-item {
  position: relative;
  box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.24);
}
@media only screen and (min-width: 1025px) {
  .catalog-list-item:hover {
    cursor: pointer;
  }
  .catalog-list-item:hover .item-hover {
    opacity: 1;
  }
}
.catalog-list-item .image-wrapper {
  height: 100%;
  border: 0.78125vw solid white;
}
@media only screen and (min-width: 1281px) {
  .catalog-list-item .image-wrapper {
    border: 10px solid white;
  }
}
@media only screen and (max-width: 767px) {
  .catalog-list-item .image-wrapper {
    border: 0.8vw solid white;
  }
}
.catalog-list-item .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.catalog-list-item .item-hover {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.catalog-list-item .item-hover-content {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 50%;
  padding: 0 0.78125vw 0.78125vw;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  text-align: center;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 17%, rgba(0, 0, 0, 0.94) 65%);
}
@media only screen and (min-width: 1281px) {
  .catalog-list-item .item-hover-content {
    padding: 0 10px 10px;
  }
}
.catalog-list-item .item-hover-content .hover-title {
  padding-bottom: 1.328125vw;
  font-size: 1.875vw;
  line-height: 1.21;
  font-weight: 600;
}
@media only screen and (min-width: 1281px) {
  .catalog-list-item .item-hover-content .hover-title {
    padding-bottom: 17px;
    font-size: 24px;
  }
}
.catalog-list-item .item-hover-content .socials {
  display: flex;
  padding-top: 1.40625vw;
  margin: 0 2.109375vw;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: right;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}
@media only screen and (min-width: 1281px) {
  .catalog-list-item .item-hover-content .socials {
    padding-top: 18px;
    margin: 0 27px;
  }
}
.catalog-list-item .item-hover-content .socials .socials-text {
  display: inline-block;
  padding-bottom: 0.46875vw;
  font-size: 1.25vw;
}
@media only screen and (min-width: 1281px) {
  .catalog-list-item .item-hover-content .socials .socials-text {
    padding-bottom: 6px;
    font-size: 16px;
  }
}

.tags-list {
  padding: 2.8125vw 0 1.5625vw;
  overflow: hidden;
}
@media only screen and (min-width: 1281px) {
  .tags-list {
    padding: 36px 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list {
    padding: 8.5333333333vw 0 6.1333333333vw;
  }
}
.tags-list-inner {
  display: flex;
  margin-right: -0.9375vw;
  margin-left: -0.9375vw;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (min-width: 1281px) {
  .tags-list-inner {
    margin-right: -12px;
    margin-left: -12px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list-inner {
    margin-right: 0vw;
    margin-left: 0vw;
  }
}
.tags-list .tags-row {
  display: flex;
  height: auto;
  padding: 0 1.796875vw;
  justify-content: space-between;
  overflow: hidden;
}
@media only screen and (min-width: 1281px) {
  .tags-list .tags-row {
    height: auto;
    padding: 0 23px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list .tags-row {
    width: 100%;
    height: auto;
    padding: 0;
    justify-content: center;
  }
}
.tags-list .tags-row:first-child {
  justify-content: space-between;
}
.tags-list .tags-row-1 {
  width: 89.0625vw;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-around;
}
@media only screen and (min-width: 1281px) {
  .tags-list .tags-row-1 {
    width: 1150px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list .tags-row-1 {
    width: 100%;
    padding-right: 1.3333333333vw;
    padding-left: 1.8666666667vw;
    margin-right: 0;
    margin-left: 0;
  }
  .tags-list .tags-row-1 .tags-list-item {
    min-width: 27.7333333333vw;
    padding-right: 3.2vw;
    padding-left: 3.2vw;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list .tags-row-2 {
    width: 100%;
    margin: 0 auto;
  }
}
.tags-list-item {
  position: relative;
  z-index: 1;
  display: flex;
  min-width: 11.5625vw;
  padding: 0.546875vw 3.125vw;
  margin: 0 0.9375vw;
  margin-bottom: 1.953125vw;
  justify-content: center;
  align-items: center;
  background-color: #dadada;
  font-size: 1.25vw;
  white-space: nowrap;
  border-radius: 3.90625vw;
  text-align: center;
  transition: 0.3s;
}
@media only screen and (min-width: 1281px) {
  .tags-list-item {
    height: 38px;
    min-width: 148px;
    padding: 7px 40px;
    margin: 0 12px;
    margin-bottom: 25px;
    font-size: 16px;
    border-radius: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list-item {
    height: 6.6666666667vw;
    min-width: 27.7333333333vw;
    padding: 0.8vw 4.2666666667vw 0.8vw;
    margin: 0 0vw 3.4666666667vw;
    margin-right: 2.6666666667vw;
    border-radius: 13.3333333333vw;
    font-size: 3.7333333333vw;
    letter-spacing: -0.26px;
    color: #191919;
  }
  .tags-list-item:first-child {
    margin-right: 0;
  }
}
.tags-list-item::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 3.90625vw;
  background: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
}
@media only screen and (min-width: 1281px) {
  .tags-list-item::after {
    border-radius: 50px;
  }
}
.tags-list-item:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
  color: white;
  cursor: pointer;
}
.tags-list-item:hover::after {
  opacity: 1;
  visibility: visible;
}
.tags-list-item-selected {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
  color: white;
}
.tags-list-item-selected::after {
  opacity: 1;
  visibility: visible;
}

.no-data {
  font-size: 3.90625vw;
  font-weight: 600;
  text-align: center;
  color: #dc2179;
}
@media only screen and (min-width: 1281px) {
  .no-data {
    font-size: 50px;
  }
}

.search-socials {
  display: flex;
  padding-bottom: 1.796875vw;
  align-items: end;
}
@media only screen and (min-width: 1281px) {
  .search-socials {
    padding-bottom: 23px;
  }
}
@media only screen and (max-width: 767px) {
  .search-socials {
    padding-bottom: 4vw;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
.search-socials-text {
  color: #292929;
  font-weight: bold;
  font-size: 2.109375vw;
}
@media only screen and (min-width: 1281px) {
  .search-socials-text {
    font-size: 27px;
  }
}
@media only screen and (max-width: 767px) {
  .search-socials-text {
    display: block;
    padding-bottom: 6.1333333333vw;
    font-size: 6.4vw;
  }
}