@charset "UTF-8";
/* stylelint-disable order/order */
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.text-field {
  position: relative;
  display: block;
  text-align: left;
}
.text-field .input-wrapper {
  position: relative;
}
.text-field .input-wrapper input {
  position: relative;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 15.859375vw;
  height: 2.96875vw;
  padding: 0 1.484375vw;
  padding-top: 0.546875vw;
  padding-bottom: 0.546875vw;
  line-height: 1.71875vw;
  border-radius: 50px;
  border: 1px solid #fff;
  outline: none;
  font-size: 1.40625vw;
  background-color: #181818;
  color: #fff;
}
@media only screen and (min-width: 1281px) {
  .text-field .input-wrapper input {
    width: 203px;
    height: 38px;
    padding: 0 19px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 18px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .text-field .input-wrapper input {
    width: 72.5333333333vw;
    height: 10.1333333333vw;
    padding: 0 3.2vw;
    line-height: 10.6666666667vw;
    border-radius: 50px;
    font-size: 4.2666666667vw;
  }
}
.text-field .input-wrapper input:focus {
  border: none;
}
.text-field .input-wrapper input.inline {
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid #707070;
}
.text-field .input-wrapper input.error {
  border: 1px solid red;
}
.text-field .input-wrapper.active::before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 99.5%;
  height: 105%;
  background: linear-gradient(to right, #da4d6d, #f045b1);
  border-radius: 50px;
}
@media only screen and (min-width: 1281px) {
  .text-field .input-wrapper.active::before {
    top: -1px;
    right: -1px;
  }
}
@media only screen and (max-width: 767px) {
  .text-field .input-wrapper.active::before {
    top: -0.3733333333vw;
    right: -0.3466666667vw;
    width: 101%;
    height: 107%;
  }
}
.text-field .input-wrapper.active .placeholder {
  color: #fff !important;
}
.text-field .placeholder {
  position: absolute;
  top: 0.546875vw;
  right: 1.5625vw;
  bottom: 0;
  z-index: 3;
  margin: auto;
  font-weight: 100;
  font-size: 1.40625vw;
  text-align: right;
  color: #999;
  pointer-events: none;
}
@media only screen and (min-width: 1281px) {
  .text-field .placeholder {
    top: 7px;
    right: 20px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .text-field .placeholder {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    font-size: 4.2666666667vw;
  }
}
.text-field .placeholder.hide {
  display: none;
}
.text-field .placeholder.for-textarea {
  top: 0.546875vw;
  right: 1.25vw;
}
@media only screen and (max-width: 767px) {
  .text-field .placeholder.for-textarea {
    top: 3.4666666667vw;
    right: 4.2666666667vw;
  }
}

.error {
  position: absolute;
  right: 1.40625vw;
  bottom: -1.953125vw;
  left: 0;
  height: 30px;
  font-size: 1.09375vw;
  color: rgb(201, 6, 6);
  text-align: right;
}
@media only screen and (min-width: 1281px) {
  .error {
    right: 18px;
    bottom: -25px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .error {
    right: 4.2666666667vw;
    bottom: -8vw;
    font-size: 3.7333333333vw;
  }
}