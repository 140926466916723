@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.tags-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  text-align: center;
  transition: 0.3s;
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .tags-popup {
    height: 100%;
  }
}
.tags-popup .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: inherit;
  background: rgba(0, 0, 0, 0.49);
}
@media only screen and (max-width: 767px) {
  .tags-popup .overlay {
    height: 100%;
  }
}
.tags-popup-content {
  position: relative;
  width: 36.796875vw;
  max-height: 90%;
  padding: 3.59375vw 0.546875vw 3.59375vw 0.546875vw;
  margin: 0 auto 0;
  background-color: white;
  border-radius: 3.203125vw;
  box-shadow: 0 8px 24px 12px rgba(15, 15, 15, 0.25);
}
@media only screen and (min-width: 1281px) {
  .tags-popup-content {
    width: 471px;
    padding: 46px 7px;
    border-radius: 41px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-popup-content {
    width: 76.5333333333vw;
    padding: 9.8666666667vw 1.8666666667vw;
    border-radius: 5.0666666667vw;
    box-shadow: 0 8px 24px 12px rgba(15, 15, 15, 0.25);
  }
}
.tags-popup .close-icon {
  position: absolute;
  top: 1.71875vw;
  right: 2.109375vw;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media only screen and (min-width: 1281px) {
  .tags-popup .close-icon {
    top: 22px;
    right: 27px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-popup .close-icon {
    top: 3.4666666667vw;
    right: 4vw;
  }
}
.tags-popup .close-icon svg {
  width: 100%;
  height: 100%;
}
.tags-popup .tags-title {
  padding-bottom: 2.5vw;
  font-size: 2.65625vw;
  font-weight: 600;
  color: #dc2179;
  text-align: center;
}
@media only screen and (min-width: 1281px) {
  .tags-popup .tags-title {
    padding-bottom: 32px;
    font-size: 34px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-popup .tags-title {
    padding-bottom: 1.3333333333vw;
    font-size: 6.4vw;
    font-weight: 400;
  }
}
.tags-popup .button {
  padding: 1.09375vw 2.109375vw 0.78125vw;
}
@media only screen and (min-width: 1281px) {
  .tags-popup .button {
    padding: 14px 27px 10px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-popup .button {
    padding: 2.9333333333vw 4.8vw 2.6666666667vw;
  }
}

.tags-list {
  box-sizing: border-box;
  height: 30.3125vw;
  margin-bottom: 1.71875vw;
  overflow: hidden;
  border: 1px solid #ececec;
  border-radius: 0.78125vw;
}
@media only screen and (min-width: 1281px) {
  .tags-list {
    height: 374px;
    padding-bottom: 17px;
    margin-bottom: 22px;
    border-radius: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list {
    height: 82.4vw;
    padding-bottom: 3.7333333333vw;
    margin-bottom: 5.3333333333vw;
    border-radius: 2.6666666667vw;
  }
}
.tags-list-inner {
  height: 100%;
  padding: 2.1875vw 0 0;
  overflow-y: scroll;
}
@media only screen and (min-width: 1281px) {
  .tags-list-inner {
    height: 388px;
    padding: 28px 0 17px;
    border-radius: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list-inner {
    padding: 5.8666666667vw 0 0;
  }
}
.tags-list .tags-row {
  display: flex;
  height: auto;
  padding: 0 1.796875vw;
  justify-content: space-between;
  overflow: hidden;
}
@media only screen and (min-width: 1281px) {
  .tags-list .tags-row {
    height: auto;
    padding: 0 23px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list .tags-row {
    width: 100%;
    height: auto;
    padding: 0;
    justify-content: center;
  }
}
.tags-list .tags-row:first-child {
  justify-content: space-between;
}
.tags-list-item {
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 10.546875vw;
  padding: 0.546875vw 1.953125vw 0.546875vw;
  margin: 0 0.390625vw 1.5625vw 0.390625vw;
  background-color: #dadada;
  font-size: 1.25vw;
  white-space: nowrap;
  border-radius: 3.90625vw;
  text-align: center;
  transition: 0.3s;
}
@media only screen and (min-width: 1281px) {
  .tags-list-item {
    height: 38px;
    min-width: 135px;
    padding: 8px 25px 7px;
    margin: 0 5px 20px;
    font-size: 16px;
    border-radius: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list-item {
    height: 6.6666666667vw;
    min-width: 27.7333333333vw;
    padding: 0.8vw 4.2666666667vw 0.8vw;
    margin: 0 0vw 2.6666666667vw 4vw;
    border-radius: 13.3333333333vw;
    font-size: 3.7333333333vw;
    letter-spacing: -0.26px;
    color: #191919;
  }
  .tags-list-item:first-child {
    margin-right: 0;
  }
}
.tags-list-item::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 3.90625vw;
  background: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
}
@media only screen and (min-width: 1281px) {
  .tags-list-item::after {
    border-radius: 50px;
  }
}
@media only screen and (min-width: 1025px) {
  .tags-list-item:nth-child(5n+4) {
    margin: 0 1.5625vw 1.5625vw 0.390625vw;
  }
}
@media only screen and (min-width: 1281px) {
  .tags-list-item:nth-child(5n+4) {
    margin: 0 20px 20px 5px;
  }
}
@media only screen and (min-width: 1025px) {
  .tags-list-item:nth-child(5n+5) {
    margin: 0 0.390625vw 1.5625vw 1.5625vw;
  }
}
@media only screen and (min-width: 1281px) {
  .tags-list-item:nth-child(5n+5) {
    margin: 0 5px 20px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list-item:nth-child(3n) {
    margin: 0 8vw 2.6666666667vw 8vw;
  }
}
@media only screen and (max-width: 767px) {
  .tags-list-item:nth-child(3n+1) {
    margin: 0 1.3333333333vw 2.6666666667vw 4vw;
  }
}
.tags-list-item:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
  color: white;
  cursor: pointer;
}
.tags-list-item:hover::after {
  opacity: 1;
  visibility: visible;
}
.tags-list-item-selected {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
  color: white;
}
.tags-list-item-selected::after {
  opacity: 1;
  visibility: visible;
}

@keyframes shake {
  10%, 90% {
    transform: scale(1);
  }
  20%, 80% {
    transform: scale(1.01);
  }
  40%, 60% {
    transform: scale(1.025);
  }
}