@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.vodSection {
  position: relative;
  width: 100%;
  height: 56.25vw;
  max-width: 100vw;
  margin: 0 auto;
  overflow: hidden;
}
@media only screen and (min-width: 1281px) {
  .vodSection {
    height: 720px;
    max-width: 1280px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .vodSection {
    height: 124.5333333333vw;
    background-image: linear-gradient(to bottom, #000 17%, rgba(24, 24, 24, 0.92) 92%);
  }
}
.vodSection .header {
  position: absolute;
  top: 3.75vw;
  right: 1.5625vw;
  z-index: 23;
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
}
@media only screen and (min-width: 1281px) {
  .vodSection .header {
    top: 48px;
    right: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .vodSection .header {
    z-index: 4;
    display: flex;
    height: 57.0666666667vw;
    min-width: 85.3333333333vw;
    padding-right: 2.6666666667vw;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;
  }
}
.vodSection .header .videoTitle {
  width: 31.25vw;
  color: #fff;
  font-size: 2.65625vw;
  line-height: 3.671875vw;
  font-weight: 700;
}
@media only screen and (min-width: 1281px) {
  .vodSection .header .videoTitle {
    width: 400px;
    font-size: 34px;
    line-height: 47px;
  }
}
@media only screen and (max-width: 767px) {
  .vodSection .header .videoTitle {
    width: 90%;
    font-size: 4.8vw;
    line-height: 6.4vw;
  }
}
.vodSection .title {
  margin-bottom: 1.953125vw;
  font-size: 2.1875vw;
  color: #ff268c;
  font-weight: 700;
}
@media only screen and (min-width: 1281px) {
  .vodSection .title {
    margin-bottom: 25px;
    font-size: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .vodSection .title {
    font-size: 5.3333333333vw;
  }
}
.vodSection .videoWrapper {
  position: relative;
}
.vodSection .videoWrapper::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12.2666666667vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #121212);
  pointer-events: none;
}
.vodSection .video-overlay {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 90%;
}
.vodSection .video {
  width: 100%;
  cursor: pointer;
  object-fit: cover;
}
@media only screen and (max-width: 767px) {
  .vodSection .video {
    height: 100%;
    object-fit: contain;
  }
}
.vodSection .playButton {
  position: absolute;
  top: 13.359375vw;
  right: 0;
  left: 0.3125vw;
  z-index: 25;
  width: 8.984375vw;
  margin: auto;
  cursor: pointer;
}
@media only screen and (min-width: 1281px) {
  .vodSection .playButton {
    top: 171px;
    left: 4px;
    width: 115px;
  }
}
@media only screen and (max-width: 767px) {
  .vodSection .playButton {
    top: 21.3333333333vw;
    width: 16vw;
  }
}
.vodSection .topGradient {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 29.765625vw;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0), #000);
  pointer-events: none;
}
@media only screen and (min-width: 1281px) {
  .vodSection .topGradient {
    height: 381px;
  }
}
@media only screen and (max-width: 767px) {
  .vodSection .topGradient {
    height: 33.8666666667vw;
  }
}
.vodSection .bottomGradient {
  position: relative;
  bottom: 15.3125vw;
  z-index: 25;
  height: 15.3125vw;
  pointer-events: none;
}
@media only screen and (min-width: 1281px) {
  .vodSection .bottomGradient {
    bottom: 196px;
    height: 196px;
  }
}
@media only screen and (max-width: 767px) {
  .vodSection .bottomGradient {
    bottom: 8vw;
    z-index: 0;
    height: 18.6666666667vw;
  }
}
.vodSection .bottomGradient::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #121212 50%);
  pointer-events: none;
  opacity: 1;
  transition: 1s;
}
@media only screen and (max-width: 767px) {
  .vodSection .bottomGradient::after {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #0d0d0d 50%);
  }
}
.vodSection .bottomGradient::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
  opacity: 0;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .vodSection .bottomGradient::before {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #0d0d0d 50%);
  }
}
.vodSection .bottomGradient.lightGradient::after {
  bottom: -200px;
  opacity: 0;
}
.vodSection .hideHeader {
  top: -1.5625vw;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1281px) {
  .vodSection .hideHeader {
    top: -20px;
  }
}
@media only screen and (max-width: 767px) {
  .vodSection .hideHeader {
    top: 4.8vw;
    right: -5.3333333333vw;
  }
}

.items {
  position: absolute;
  right: 0;
  bottom: 3.28125vw;
  left: 0;
  display: flex;
  padding-right: 3.90625vw;
  padding-left: 3.90625vw;
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
}
@media only screen and (min-width: 1281px) {
  .items {
    bottom: 42px;
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .items {
    bottom: 0.5333333333vw;
    padding-right: 4.5333333333vw;
    padding-left: 0;
    overflow-x: visible;
  }
}
.items .swiper {
  position: relative;
  z-index: 30;
  width: 100%;
  height: 100%;
}
.items .swiper .swiperSlide {
  width: auto !important;
  height: 100%;
}
.items .swiper .swiperSlide .bottomGradientItem {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  width: 100%;
  height: 6.25vw;
  background: rgb(2, 0, 36);
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,121212+20,121212+37,121212+74&0+0,0.45+20,0.67+37,1+74 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(18, 18, 18, 0.45) 20%, rgba(18, 18, 18, 0.67) 37%, rgb(18, 18, 18) 74%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(18, 18, 18, 0.45) 20%, rgba(18, 18, 18, 0.67) 37%, rgb(18, 18, 18) 74%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(18, 18, 18, 0.45) 20%, rgba(18, 18, 18, 0.67) 37%, rgb(18, 18, 18) 74%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#00000000", endColorstr="#121212", GradientType=0); /* IE6-9 */
  pointer-events: none;
}
@media only screen and (min-width: 1281px) {
  .items .swiper .swiperSlide .bottomGradientItem {
    height: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .items .swiper .swiperSlide .bottomGradientItem {
    height: 25.3333333333vw;
  }
}

.item {
  position: relative;
  display: flex;
  width: 17.1875vw;
  height: 17.1875vw;
  padding-right: 1.5625vw;
  padding-bottom: 1.5625vw;
  padding-left: 1.5625vw;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
  background-size: cover !important;
  background-position: 50% 50% !important;
}
@media only screen and (min-width: 1281px) {
  .item {
    width: 220px;
    height: 220px;
    padding-right: 12px;
    padding-bottom: 18px;
    padding-left: 9px;
  }
}
@media only screen and (max-width: 767px) {
  .item {
    width: 50.6666666667vw;
    height: 50.6666666667vw;
    padding-right: 2.1333333333vw;
    padding-bottom: 3.7333333333vw;
    padding-left: 3.4666666667vw;
  }
}
.item .bottom {
  position: relative;
  z-index: 26;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: end;
}
.item .playIcon {
  width: 2.5vw;
  height: 2.5vw;
  margin-top: -0.15625vw;
}
@media only screen and (min-width: 1281px) {
  .item .playIcon {
    width: 32px;
    height: 32px;
    margin-top: -2px;
  }
}
@media only screen and (max-width: 767px) {
  .item .playIcon {
    width: 6.6666666667vw;
    height: 6.6666666667vw;
  }
}
.item .videoTitle {
  width: 80%;
  font-size: 1.25vw;
  line-height: 1.3;
  color: #fff;
  font-weight: 700;
}
@media only screen and (min-width: 1281px) {
  .item .videoTitle {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .item .videoTitle {
    font-size: 4.2666666667vw;
    line-height: 5.8666666667vw;
  }
}

.hideItems {
  bottom: -2.34375vw;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1281px) {
  .hideItems {
    bottom: -30px;
  }
}

.prevArrow,
.nextArrow {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 40;
  display: flex;
  width: 2.34375vw;
  height: 2.34375vw;
  margin: auto;
  justify-content: center;
  align-items: center;
  border-radius: 7.1px;
  box-shadow: 0 4px 12px 6px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.3s;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1281px) {
  .prevArrow,
  .nextArrow {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .prevArrow,
  .nextArrow {
    width: 8vw;
    height: 8vw;
  }
}
.prevArrow.visible,
.nextArrow.visible {
  opacity: 1;
  visibility: visible;
}

.prevArrow {
  right: 0.78125vw;
}
@media only screen and (min-width: 1281px) {
  .prevArrow {
    right: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .prevArrow {
    right: 5.6vw;
  }
}

.nextArrow {
  left: 0.78125vw;
}
@media only screen and (min-width: 1281px) {
  .nextArrow {
    left: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .nextArrow {
    left: 5.6vw;
  }
}

.rightIcon {
  transform: rotate(180deg);
}