@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.datepicker-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  text-align: center;
  transition: 0.3s;
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  .datepicker-popup {
    height: 100%;
  }
}
.datepicker-popup .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: inherit;
  background: rgba(0, 0, 0, 0.49);
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .overlay {
    height: 100%;
  }
}
.datepicker-popup-content {
  position: relative;
  width: 36.09375vw;
  max-height: 90%;
  padding: 3.4375vw 5.15625vw 3.59375vw;
  margin: 0 auto 0;
  background-color: white;
  border-radius: 3.203125vw;
  box-shadow: 0 8px 24px 12px rgba(15, 15, 15, 0.25);
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup-content {
    width: 462px;
    padding: 44px 66px 46px;
    border-radius: 41px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup-content {
    width: 76.5333333333vw;
    padding: 9.0666666667vw 0;
    border-radius: 5.0666666667vw;
    box-shadow: 0 8px 24px 12px rgba(15, 15, 15, 0.25);
  }
}
.datepicker-popup .close-icon {
  position: absolute;
  top: 0.78125vw;
  right: 1.171875vw;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .close-icon {
    top: 2.6666666667vw;
    right: 2.6666666667vw;
  }
}
.datepicker-popup .datepicker-title {
  padding-bottom: 1.875vw;
  font-size: 2.65625vw;
  font-weight: 600;
  color: #dc2179;
  text-align: center;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .datepicker-title {
    padding-bottom: 24px;
    font-size: 34px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .datepicker-title {
    padding-bottom: 5.3333333333vw;
    font-size: 6.4vw;
  }
}
.datepicker-popup .select-year {
  position: relative;
  width: 17.5vw;
  margin: 0.3125vw auto 2.1875vw;
  border-radius: 3.90625vw;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .select-year {
    width: 224px;
    margin: 4px auto 28px;
    border-radius: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .select-year {
    width: 47.4666666667vw;
    margin: 1.6vw auto 6.9333333333vw;
    border-radius: 13.3333333333vw;
  }
}
.datepicker-popup .select-year::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  margin: -1px;
  border-radius: inherit;
  background: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
}
.datepicker-popup .select-year-holder {
  position: relative;
  z-index: 2;
  display: block;
  height: 3.125vw;
  padding: 0.546875vw 2.03125vw 0.546875vw 3.515625vw;
  border-radius: 3.90625vw;
  color: #999;
  font-size: 1.71875vw;
  background-color: white;
  text-align: right;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .select-year-holder {
    height: 40px;
    padding: 7px 26px 7px 45px;
    border-radius: 50px;
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .select-year-holder {
    height: 8.2666666667vw;
    padding: 5px 20px 7px 40px;
    font-size: 4.8vw;
    border-radius: 13.3333333333vw;
    text-align: center;
  }
}
.datepicker-popup .select-year-holder .arrow-icon {
  position: absolute;
  top: 1.40625vw;
  left: 1.484375vw;
  width: 1.328125vw;
  height: 0.78125vw;
  transition: 0.15s;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .select-year-holder .arrow-icon {
    top: 18px;
    left: 19px;
    width: 17px;
    height: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .select-year-holder .arrow-icon {
    top: 3.4666666667vw;
    left: 6.9333333333vw;
    width: 3.7333333333vw;
    height: 2.4vw;
  }
}
.datepicker-popup .select-year-holder .arrow-icon svg {
  width: 100%;
  height: 100%;
}
.datepicker-popup .select-year-holder .arrow-icon.arrow-top {
  top: 1.171875vw;
  transform: rotate(180deg);
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .select-year-holder .arrow-icon.arrow-top {
    top: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .select-year-holder .arrow-icon.arrow-top {
    top: 3.2vw;
  }
}
.datepicker-popup .select-year-holder:hover {
  cursor: pointer;
}
.datepicker-popup .select-year-holder.selected {
  color: #dc2179;
}
.datepicker-popup .select-year-holder .selected-text {
  display: inline-block;
}
.datepicker-popup .select-year .notification {
  position: absolute;
  top: 3.90625vw;
  width: 100%;
  font-size: 1.5625vw;
  font-weight: 600;
  color: #dc2179;
  text-align: center;
  animation: shake 0.7s infinite;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .select-year .notification {
    top: 50px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .select-year .notification {
    top: 8vw;
    font-size: 4.2666666667vw;
  }
}
.datepicker-popup .years-list {
  position: absolute;
  top: 1.5625vw;
  left: -1px;
  width: calc(100% + 2px);
  height: 0;
  padding-top: 1.5625vw;
  overflow-y: hidden;
  border-bottom-left-radius: 1.5625vw;
  border-bottom-right-radius: 1.5625vw;
  border: 1px solid #dc2179;
  background-color: white;
  opacity: 0;
  transition: all 0.3s;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .years-list {
    top: 20px;
    padding-top: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .years-list {
    top: 5.3333333333vw;
    padding-top: 5.3333333333vw;
    border-bottom-left-radius: 5.0666666667vw;
    border-bottom-right-radius: 5.0666666667vw;
  }
}
.datepicker-popup .years-list.opened-select {
  z-index: 1;
  height: 22.109375vw;
  opacity: 1;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.22);
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .years-list.opened-select {
    height: 283px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .years-list.opened-select {
    height: 68vw;
  }
}
.datepicker-popup .years-list-inner {
  height: 22.109375vw;
  padding-bottom: 3.125vw;
  overflow-y: scroll;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .years-list-inner {
    height: 283px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .years-list-inner {
    height: 68vw;
    padding-bottom: 10.6666666667vw;
  }
}
.datepicker-popup .years-list-item {
  padding: 0.546875vw 0 0.3125vw;
  margin: 0 4.6875vw;
  font-size: 1.40625vw;
  color: #292929;
  border-bottom: 1px solid #cecece;
  text-align: center;
  transition: 0.25s;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .years-list-item {
    padding: 7px 0 4px;
    margin: 0 60px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .years-list-item {
    padding: 1.8666666667vw 0 1.0666666667vw;
    margin: 0 16vw;
    font-size: 4.8vw;
  }
}
.datepicker-popup .years-list-item:last-child {
  border-bottom: none;
}
.datepicker-popup .years-list-item:hover {
  cursor: pointer;
  color: #dc2179;
}
.datepicker-popup .years-list-item.selected-year {
  color: #dc2179;
}
.datepicker-popup .months-list {
  display: grid;
  padding-bottom: 2.265625vw;
  margin-top: -0.390625vw;
  justify-content: center;
  grid-template-rows: repeat(3, 72px);
  grid-template-columns: repeat(4, 72px);
  row-gap: 10px;
  column-gap: 15px;
  text-align: center;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .months-list {
    padding-bottom: 29px;
    margin-top: -5px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .months-list {
    padding-bottom: 7.2vw;
    grid-template-rows: repeat(3, 45px);
    grid-template-columns: repeat(4, 45px);
    row-gap: 10px;
    column-gap: 10px;
  }
}
.datepicker-popup .months-list-item {
  position: relative;
  width: 72px;
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .months-list-item {
    width: 46px;
    height: 46px;
    overflow: hidden;
  }
}
@media only screen and (min-width: 1025px) {
  .datepicker-popup .months-list-item:hover {
    cursor: pointer;
  }
  .datepicker-popup .months-list-item:hover .icon-month-selected {
    visibility: visible;
    opacity: 1;
  }
  .datepicker-popup .months-list-item:hover .icon-month {
    opacity: 0;
    visibility: hidden;
  }
  .datepicker-popup .months-list-item:hover .month-name {
    font-weight: 700;
  }
}
.datepicker-popup .months-list-item.selected-month .icon-month-selected {
  visibility: visible;
  opacity: 1;
}
.datepicker-popup .months-list-item.selected-month .icon-month {
  opacity: 0;
  visibility: hidden;
}
.datepicker-popup .months-list-item.selected-month .month-name {
  font-weight: 700;
}
.datepicker-popup .months-list-item .month-name {
  position: absolute;
  bottom: 0.234375vw;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #dc2179;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .months-list-item .month-name {
    bottom: 3px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .months-list-item .month-name {
    right: 0;
    bottom: 1.3333333333vw;
    font-size: 12px;
  }
}
.datepicker-popup .months-list-item .icon-month {
  visibility: visible;
  opacity: 1;
  transition: 0.25s;
}
.datepicker-popup .months-list-item .icon-month-selected {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.25s;
}
.datepicker-popup .months-list-item .icon-month-mobile {
  position: relative;
  width: 100%;
  height: 100%;
}
.datepicker-popup .months-list-item .icon-month-mobile .icon-month-bg,
.datepicker-popup .months-list-item .icon-month-mobile .icon-month-selected-bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: inherit;
  background-size: cover;
}
.datepicker-popup .months-list-item .icon-month-mobile .icon-month-selected-bg {
  visibility: visible;
  opacity: 0;
}
.datepicker-popup .button {
  padding: 1.09375vw 2.109375vw 0.78125vw;
}
@media only screen and (min-width: 1281px) {
  .datepicker-popup .button {
    padding: 14px 27px 10px;
  }
}
@media only screen and (max-width: 767px) {
  .datepicker-popup .button {
    padding: 2.9333333333vw 4.8vw 2.6666666667vw;
  }
}

@keyframes shake {
  10%, 90% {
    transform: scale(1);
  }
  20%, 80% {
    transform: scale(1.01);
  }
  40%, 60% {
    transform: scale(1.025);
  }
}