@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.timelineMobileWrapper {
  position: relative;
  z-index: 45;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.timelineMobileWrapper .title {
  margin-bottom: 5.0666666667vw;
  font-size: 6.4vw;
  font-weight: 700;
  color: #000;
}
.timelineMobileWrapper .content {
  width: 70.1333333333vw;
  min-height: 41.6vw;
  margin-bottom: 5.3333333333vw;
  font-size: 4.2666666667vw;
  line-height: 6.9333333333vw;
  color: #292929;
  text-align: center;
}
.timelineMobileWrapper .content .editedContent::after {
  content: "... ";
}
.timelineMobileWrapper .showPopUp {
  margin-bottom: 6.1333333333vw;
  text-align: center;
  font-size: 4.2666666667vw;
  color: #292929;
  text-decoration: underline;
}
.timelineMobileWrapper .year {
  position: absolute;
  right: 2.9333333333vw;
  bottom: 5.8666666667vw;
  font-size: 11.7333333333vw;
  font-weight: 700;
}
.timelineMobileWrapper .swiper {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
}
.timelineMobileWrapper .swiper .swiperSlide {
  display: flex;
  width: 57.6vw;
  height: 78.6666666667vw;
  justify-content: center;
  align-items: center;
}
.timelineMobileWrapper .swiper .coverImage {
  width: 57.6vw;
  height: 78.6666666667vw;
}

.sliderWrapper {
  position: relative;
  z-index: 30;
  display: flex;
  width: 100%;
  padding-right: 4vw;
  padding-left: 4vw;
  margin-top: 24.2666666667vw;
}
.sliderWrapper .slider {
  width: 100%;
  height: 1.3333333333vw;
  -webkit-appearance: none;
  border-radius: 5px;
  background: #1e1e1e;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}
.sliderWrapper .slider::-webkit-slider-thumb {
  width: 5.3333333333vw;
  height: 5.3333333333vw;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 50%;
  background: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
  cursor: pointer;
}
.sliderWrapper .slider::-ms-fill-lower {
  background: #2a6495;
  box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}
.sliderWrapper .slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}