@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.leads {
  height: auto;
  max-width: 100vw;
  padding-top: 9.921875vw;
  padding-bottom: 10.703125vw;
  margin: 0 auto;
  text-align: center;
  background-size: cover !important;
  background-position: center;
}
@media only screen and (min-width: 1281px) {
  .leads {
    max-width: 1280px;
    padding-top: 127px;
    padding-bottom: 137px;
  }
}
@media only screen and (max-width: 767px) {
  .leads {
    padding-top: 7.2vw;
    padding-bottom: 17.8666666667vw;
    background-position: top center;
  }
}
.leads .titleWrapper {
  display: flex;
  padding: 0 0 0.390625vw 0;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1281px) {
  .leads .titleWrapper {
    padding: 0 0 5px 0;
  }
}
@media only screen and (max-width: 767px) {
  .leads .titleWrapper {
    padding: 0 0.5333333333vw 2.1333333333vw 0;
  }
}
.leads .titleWrapper .title {
  font-size: 3.4375vw;
  color: #fff;
  font-weight: 700;
}
@media only screen and (min-width: 1281px) {
  .leads .titleWrapper .title {
    font-size: 44px;
  }
}
@media only screen and (max-width: 767px) {
  .leads .titleWrapper .title {
    font-size: 6.4vw;
  }
}
.leads .titleWrapper .logo {
  width: 10.9375vw;
  height: 2.03125vw;
  margin-top: 0.3125vw;
  margin-right: 1.015625vw;
  margin-left: 1.015625vw;
}
@media only screen and (min-width: 1281px) {
  .leads .titleWrapper .logo {
    width: 140px;
    height: 26px;
    margin-top: 4px;
    margin-right: 13px;
    margin-left: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .leads .titleWrapper .logo {
    width: 24.5333333333vw;
    height: 4.8vw;
    margin-top: 0.2666666667vw;
    margin-right: 2.4vw;
    margin-left: 2.1333333333vw;
  }
}
.leads .subTitle {
  margin-bottom: 3.046875vw;
  font-size: 1.875vw;
  color: #fff;
  font-weight: 600;
  white-space: pre-line;
}
@media only screen and (min-width: 1281px) {
  .leads .subTitle {
    margin-bottom: 39px;
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .leads .subTitle {
    margin-bottom: 6.4vw;
    font-size: 4.8vw;
  }
}
.leads .externalLink {
  display: flex;
  width: 16.015625vw;
  height: 3.90625vw;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.5625vw;
  text-decoration: none;
  background: linear-gradient(to left, #f63f55, #e041a5);
  border-radius: 50px;
  font-weight: 700;
}
@media only screen and (min-width: 1281px) {
  .leads .externalLink {
    width: 205px;
    height: 50px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .leads .externalLink {
    width: 32vw;
    height: 11.2vw;
    font-size: 4.8vw;
  }
}