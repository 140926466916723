@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.celebsNavigation {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: flex;
  width: 100%;
  margin-bottom: 0.859375vw;
  overflow-x: clip;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation {
    margin-bottom: 11px;
  }
}
@media only screen and (max-width: 767px) {
  .celebsNavigation {
    bottom: 8.8vw;
    padding: 0;
    overflow-x: initial;
  }
}
.celebsNavigation .swiper {
  position: relative;
  z-index: 30;
  width: 100%;
  height: 18.75vw;
  overflow-x: hidden;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .swiper {
    height: 240px;
  }
}
@media only screen and (max-width: 767px) {
  .celebsNavigation .swiper {
    height: 100%;
    overflow: visible;
  }
}
.celebsNavigation .swiper::before, .celebsNavigation .swiper::after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 50;
  display: none;
  width: 0.15625vw;
  height: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .swiper::before, .celebsNavigation .swiper::after {
    width: 2px;
  }
}
@media only screen and (max-width: 767px) {
  .celebsNavigation .swiper::before, .celebsNavigation .swiper::after {
    display: none;
  }
}
.celebsNavigation .swiper::before {
  right: -0.15625vw;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .swiper::before {
    right: -2px;
  }
}
.celebsNavigation .swiper::after {
  left: -0.15625vw;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .swiper::after {
    left: -2px;
  }
}
.celebsNavigation .swiper .swiperSlide {
  width: auto !important;
  height: 14.0625vw;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .swiper .swiperSlide {
    height: 180px;
  }
}
.celebsNavigation .swiper .swiperSlide .bottomGradientItem {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  width: 100%;
  height: 6.25vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
  pointer-events: none;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .swiper .swiperSlide .bottomGradientItem {
    height: 80px;
  }
}
.celebsNavigation .swiper :global .swiper-wrapper {
  padding-top: 2.734375vw;
  margin-right: 0.78125vw;
  align-items: flex-start;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .swiper :global .swiper-wrapper {
    padding-top: 35px;
    margin-right: 10px;
  }
}
.celebsNavigation .swiper :global .swiper-scrollbar {
  bottom: 0;
  height: 8px;
  opacity: 1 !important;
  background-color: #d4d4d4;
}
@media only screen and (max-width: 767px) {
  .celebsNavigation .swiper :global .swiper-scrollbar {
    display: none;
  }
}
.celebsNavigation .swiper :global .swiper-scrollbar .swiper-scrollbar-drag {
  background: #f045b1;
}
.celebsNavigation .itemBgWrapper {
  position: relative;
  margin-bottom: 1.5625vw;
  transition: 0.5s;
  border-radius: 50%;
  background-color: white;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .itemBgWrapper {
    margin-bottom: 20px;
  }
}
.celebsNavigation .itemBgWrapper.active .item::before {
  opacity: 1 !important;
}
.celebsNavigation .itemWrapper {
  display: flex;
  width: 14.0625vw;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .itemWrapper {
    width: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .celebsNavigation .itemWrapper {
    width: 94px;
    margin-right: 0;
  }
}
.celebsNavigation .itemWrapper .item {
  position: relative;
  width: 9.375vw;
  height: 9.375vw;
  background-size: cover;
  background-position: 50% 50% !important;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-color: white;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .itemWrapper .item {
    width: 120px;
    height: 120px;
  }
}
@media only screen and (max-width: 767px) {
  .celebsNavigation .itemWrapper .item {
    width: 17.3333333333vw;
    height: 17.3333333333vw;
  }
}
.celebsNavigation .itemWrapper .item::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -9px;
  border-radius: inherit;
  opacity: 0;
  background: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .celebsNavigation .itemWrapper .item::before {
    margin: -8px;
  }
}
.celebsNavigation .itemWrapper .item::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  margin: -9px;
  border-radius: inherit;
  background-color: white;
  box-shadow: 0 1px 13px 0 rgba(111, 111, 111, 0.7);
}
@media only screen and (max-width: 767px) {
  .celebsNavigation .itemWrapper .item::after {
    margin: -8px;
  }
}
.celebsNavigation .itemWrapper .name {
  width: 180px;
  width: 14.0625vw;
  height: 30px;
  overflow: hidden;
  font-size: 1.796875vw;
  font-weight: 600;
  color: #292929;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
@media only screen and (min-width: 1281px) {
  .celebsNavigation .itemWrapper .name {
    width: 180px;
    font-size: 23px;
  }
}
@media only screen and (max-width: 767px) {
  .celebsNavigation .itemWrapper .name {
    width: 94px;
  }
}