@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.footer {
  height: 4.375vw;
  background-color: #0f0f0f;
}
@media only screen and (min-width: 1281px) {
  .footer {
    height: 56px;
  }
}
@media only screen and (max-width: 767px) {
  .footer {
    height: 100%;
  }
}
.footer-inner {
  position: relative;
  display: flex;
  max-width: 100vw;
  padding-top: 1.015625vw;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1281px) {
  .footer-inner {
    max-width: 1280px;
    padding-top: 13px;
  }
}
.footer .powered {
  width: 9.6875vw;
  height: 2.5vw;
}
@media only screen and (min-width: 1281px) {
  .footer .powered {
    width: 124px;
    height: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .footer .powered {
    order: 3;
  }
}
.footer .linkWrapper {
  position: absolute;
  top: 1.171875vw;
  right: 1.640625vw;
  bottom: 0;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1281px) {
  .footer .linkWrapper {
    top: 15px;
    right: 21px;
  }
}
@media only screen and (max-width: 767px) {
  .footer .linkWrapper {
    position: static;
    padding-bottom: 3.2vw;
  }
}
.footer .linkWrapper a {
  text-decoration: none;
  color: #fff;
  font-size: 1.25vw;
}
@media only screen and (min-width: 1281px) {
  .footer .linkWrapper a {
    font-size: 16px;
  }
}
.footer .linkWrapper p {
  margin-right: 1.5625vw;
  margin-left: 1.5625vw;
  font-size: 1.25vw;
  color: #fff;
}
@media only screen and (min-width: 1281px) {
  .footer .linkWrapper p {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 16px;
  }
}

.footerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerMobile .topTitle {
  padding-top: 4.5333333333vw;
  padding-bottom: 2.6666666667vw;
  color: #fff;
  font-size: 4.2666666667vw;
  font-weight: 600;
}
.footerMobile .poweredByWrapper {
  display: flex;
  width: 100%;
  height: 8vw;
  padding: 1.3333333333vw 0;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.footerMobile .poweredByWrapper p {
  margin-right: 1.0666666667vw;
  font-size: 3.4666666667vw;
  font-family: "Assistant", sans-serif;
}
.footerMobile .linkWrapper {
  padding-bottom: 3.7333333333vw;
  padding-left: 0;
}
.footerMobile .linkWrapper a {
  font-weight: 500;
  font-size: 3.4666666667vw;
}
.footerMobile .linkWrapper p {
  font-size: 3.4666666667vw;
  font-weight: 500;
}
.footerMobile .powered {
  position: relative;
  top: 0.5333333333vw;
  width: 5.3333333333vw;
  height: 4.5333333333vw;
}