@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.articlesSection {
  padding-top: 5.078125vw;
  padding-right: 1.5625vw;
  padding-bottom: 4.609375vw;
  padding-left: 1.5625vw;
  overflow: hidden;
}
@media only screen and (min-width: 1281px) {
  .articlesSection {
    max-width: 1280px;
    padding-top: 65px;
    padding-right: 20px;
    padding-bottom: 59px;
    padding-left: 20px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection {
    padding-top: 12.5333333333vw;
    padding-right: 4vw;
    padding-bottom: 9.4133333333vw;
    padding-left: 0;
  }
}
.articlesSection .swiper {
  position: relative;
  z-index: 30;
  width: 100%;
  height: 100%;
}
.articlesSection .swiper .swiperSlide {
  width: auto !important;
  height: 100%;
}
.articlesSection .swiper .swiperSlide .bottomGradientItem {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  width: 100%;
  height: 6.25vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
  pointer-events: none;
}
.articlesSection .header {
  display: flex;
  margin-bottom: 3.046875vw;
  justify-content: space-between;
  align-items: center;
  font-family: "Assistant", sans-serif;
  color: #fff;
}
@media only screen and (min-width: 1281px) {
  .articlesSection .header {
    margin-bottom: 39px;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .header {
    padding-left: 4.2666666667vw;
    margin-bottom: 4.2666666667vw;
  }
}
.articlesSection .header .link {
  position: relative;
  margin-top: -0.46875vw;
  font-weight: 600;
  font-size: 1.875vw;
  color: #fff;
  text-decoration: none;
}
@media only screen and (min-width: 1281px) {
  .articlesSection .header .link {
    margin-top: -6px;
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .header .link {
    margin-top: -0.5333333333vw;
    font-size: 4.2666666667vw;
  }
}
.articlesSection .header .link::after {
  content: "";
  position: absolute;
  bottom: -0.625vw;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fff;
}
.articlesSection .header .title {
  font-size: 2.65625vw;
  line-height: 1;
  font-weight: bold;
  color: #fff;
}
@media only screen and (min-width: 1281px) {
  .articlesSection .header .title {
    font-size: 34px;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .header .title {
    font-size: 5.3333333333vw;
    font-weight: 700;
  }
}
.articlesSection .items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.articlesSection .item {
  position: relative;
  z-index: 2;
  display: flex;
  margin-bottom: 1.953125vw;
  flex-direction: column;
  text-decoration: none;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}
@media only screen and (min-width: 1281px) {
  .articlesSection .item {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .item {
    margin-bottom: 4.5333333333vw;
  }
}
.articlesSection .item::after {
  content: "";
  position: absolute;
  top: -0.15625vw;
  right: -0.15625vw;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 101%;
  height: 101%;
  background: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
  pointer-events: none;
  transition: 0.3s;
  opacity: 0;
}
@media only screen and (min-width: 1281px) {
  .articlesSection .item::after {
    top: -2px;
    right: -2px;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .item::after {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .item:last-child {
    margin-bottom: 0;
  }
}
.articlesSection .item:hover::after {
  opacity: 1;
}
.articlesSection .item .img {
  position: relative;
  z-index: 2;
  width: 31.25vw;
  height: 17.96875vw;
  object-fit: cover;
}
@media only screen and (min-width: 1281px) {
  .articlesSection .item .img {
    width: 400px;
    height: 230px;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .item .img {
    width: 69.3333333333vw;
    height: 40vw;
  }
}
.articlesSection .item .bottom {
  position: relative;
  z-index: 2;
  display: flex;
  width: 31.25vw;
  height: 14.921875vw;
  padding-top: 0.78125vw;
  padding-right: 1.328125vw;
  padding-bottom: 1.25vw;
  padding-left: 0.625vw;
  flex-direction: column;
  justify-content: space-between;
  background-color: #0f0f0f;
  color: #fff;
}
@media only screen and (min-width: 1281px) {
  .articlesSection .item .bottom {
    width: 400px;
    height: 191px;
    padding-top: 10px;
    padding-right: 17px;
    padding-bottom: 16px;
    padding-left: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .item .bottom {
    width: 69.3333333333vw;
    height: 100%;
    min-height: 35.4666666667vw;
    padding-top: 1.0666666667vw;
    padding-right: 1.8666666667vw;
    padding-bottom: 2.6666666667vw;
    padding-left: 1.6vw;
  }
}
.articlesSection .item .bottom .categoryName {
  margin-bottom: 0.078125vw;
  font-size: 1.25vw;
  font-weight: 600;
}
@media only screen and (min-width: 1281px) {
  .articlesSection .item .bottom .categoryName {
    margin-bottom: 1px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .item .bottom .categoryName {
    margin-bottom: 2.9333333333vw;
    font-size: 3.4666666667vw;
    line-height: 4.2666666667vw;
  }
}
.articlesSection .item .bottom .title {
  font-weight: 700;
  font-size: 1.875vw;
  color: #fff;
}
@media only screen and (min-width: 1281px) {
  .articlesSection .item .bottom .title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .item .bottom .title {
    font-size: 4.8vw;
    line-height: 6.4vw;
  }
}
.articlesSection .item .itemFooter {
  display: flex;
  color: #999;
  font-size: 1.09375vw;
}
@media only screen and (min-width: 1281px) {
  .articlesSection .item .itemFooter {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .articlesSection .item .itemFooter {
    font-size: 3.4666666667vw;
  }
}
.articlesSection .item .itemFooter p {
  display: flex;
  align-items: center;
}

.prevArrow,
.nextArrow {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 40;
  display: flex;
  width: 8vw;
  height: 8vw;
  margin: auto;
  justify-content: center;
  align-items: center;
  border-radius: 7.1px;
  box-shadow: 0 4px 12px 6px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.7);
  transition: 0.3s;
}

.prevArrow {
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.nextArrow {
  left: 0;
}
@media only screen and (max-width: 767px) {
  .nextArrow {
    left: 4vw;
  }
}

.rightIcon {
  transform: rotate(180deg);
}

.visible {
  opacity: 1;
  visibility: visible;
}