@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.searchForm {
  width: 57.5vw;
  height: 6.25vw;
}
@media only screen and (min-width: 1281px) {
  .searchForm {
    width: 736px;
    height: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .searchForm {
    width: auto;
    height: 10.1333333333vw;
    padding-bottom: 1.3333333333vw;
  }
}

.searchField {
  position: relative;
  width: 57.03125vw;
  height: 5.78125vw;
  border-radius: 3.90625vw;
}
@media only screen and (min-width: 1281px) {
  .searchField {
    width: 730px;
    height: 74px;
    border-radius: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .searchField {
    width: 62.9333333333vw;
    height: 10.1333333333vw;
    border-radius: 13.3333333333vw;
  }
}
.searchField::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -3px;
  border-radius: inherit;
  background: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
}
@media only screen and (max-width: 767px) {
  .searchField::before {
    margin: -2px;
  }
}
.searchField.active::after {
  content: "";
  position: absolute;
  top: 5.546875vw;
  left: 0.78125vw;
  z-index: 3;
  width: calc(100% - 20px);
  height: 1px;
  background-color: #d9d7d7;
}
@media only screen and (min-width: 1281px) {
  .searchField.active::after {
    top: 71px;
    left: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .searchField.active::after {
    top: 10.1333333333vw;
    left: 5px;
    width: calc(100% - 10px);
  }
}
.searchField input {
  position: relative;
  z-index: 3;
  display: inline-block;
  width: 57.03125vw;
  height: 5.78125vw;
  padding: 0 2.34375vw 0 5.859375vw;
  border: 0;
  outline: 0;
  font-family: "Assistant", sans-serif;
  border-radius: 3.90625vw;
  transition: all 0.025s ease-in-out;
  color: #292929;
  font-size: 2.03125vw;
}
@media only screen and (min-width: 1281px) {
  .searchField input {
    width: 730px;
    height: 74px;
    padding: 0 30px 0 75px;
    border-radius: 50px;
    font-size: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .searchField input {
    width: 62.9333333333vw;
    height: 10.1333333333vw;
    padding: 0 4.2666666667vw 0 14.6666666667vw;
    border-radius: 13.3333333333vw;
    font-size: 4.2666666667vw;
  }
}
.searchField input:focus::-webkit-input-placeholder {
  opacity: 0;
}
.searchField input:focus::-moz-placeholder {
  opacity: 0;
}
.searchField input:focus:-ms-input-placeholder {
  opacity: 0;
}
.searchField input:focus:-moz-placeholder {
  opacity: 0;
}
.searchField input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: "Assistant", sans-serif;
  color: #7b7b7b;
  opacity: 1;
  transition: 0.3s;
}
.searchField input::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "Assistant", sans-serif;
  color: #7b7b7b;
  opacity: 1;
  transition: 0.3s;
}
.searchField input:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "Assistant", sans-serif;
  color: #7b7b7b;
  opacity: 1;
  transition: 0.3s;
}
.searchField input:-moz-placeholder {
  /* Firefox 18- */
  font-family: "Assistant", sans-serif;
  color: #7b7b7b;
  opacity: 1;
  transition: 0.3s;
}
.searchField input:-internal-autofill-selected, .searchField input:-webkit-autofill, .searchField input:-webkit-autofill:hover, .searchField input:-webkit-autofill:focus, .searchField input:-webkit-autofill:active {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
  box-shadow: 0 0 0 30px transparent inset !important;
  background-color: transparent !important;
}
.searchField .searchIcon {
  position: absolute;
  top: 0.390625vw;
  left: 0.46875vw;
  z-index: 3;
  width: 4.921875vw;
  height: 5vw;
  cursor: pointer;
  transition: 0.3s;
}
@media only screen and (min-width: 1281px) {
  .searchField .searchIcon {
    top: 5px;
    left: 6px;
    width: 63px;
    height: 64px;
  }
}
@media only screen and (max-width: 767px) {
  .searchField .searchIcon {
    top: 0.2666666667vw;
    left: 0.8vw;
    width: 9.6vw;
    height: 9.6vw;
  }
}
.searchField .searchIcon:hover {
  transform: scale(1.06);
}
.searchField .searchIcon svg {
  width: 100%;
  height: 100%;
}

.sugestions {
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 2;
  width: calc(100% + 6px);
  max-height: 22.5vw;
  overflow: hidden;
  border-radius: 2.421875vw;
  border: solid 3px #f54064;
  background-color: #fff;
}
@media only screen and (min-width: 1281px) {
  .sugestions {
    max-height: 288px;
  }
}
@media only screen and (max-width: 767px) {
  .sugestions {
    top: -2px;
    right: -2px;
    width: calc(100% + 4px);
    max-height: 40vw;
    border: solid 2px #f54064;
    border-radius: 5.6vw;
  }
}
.sugestions-inner {
  padding-top: 5.46875vw;
}
@media only screen and (min-width: 1281px) {
  .sugestions-inner {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .sugestions-inner {
    padding-top: 10.1333333333vw;
  }
}
.sugestions .sugestionsList {
  height: 16.40625vw;
  padding-top: 1.875vw;
  padding-bottom: 1.5625vw;
  overflow-y: auto;
}
@media only screen and (min-width: 1281px) {
  .sugestions .sugestionsList {
    height: 210px;
    padding-top: 24px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .sugestions .sugestionsList {
    height: 28.8vw;
    padding-top: 1.8666666667vw;
    padding-bottom: 1.8666666667vw;
  }
}
.sugestions .sugestionsList .sugestionItem {
  padding-right: 1.875vw;
  font-size: 2.03125vw;
  line-height: 1.46;
  text-align: right;
  background-color: transparent;
  transition: all 0.25s ease-in-out;
}
@media only screen and (min-width: 1281px) {
  .sugestions .sugestionsList .sugestionItem {
    padding-right: 24px;
    font-size: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .sugestions .sugestionsList .sugestionItem {
    padding-right: 4.8vw;
    font-size: 4.2666666667vw;
    line-height: 1.36;
  }
}
.sugestions .sugestionsList .sugestionItem:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}
.sugestions .highlight {
  font-weight: 600;
}