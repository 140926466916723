@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.popUp {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding-top: 8.8vw;
  padding-right: 6.6666666667vw;
  padding-bottom: 4.5333333333vw;
  padding-left: 6.6666666667vw;
  transition: 0.3s;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
}
.popUp .innerWindow {
  position: relative;
  display: flex;
  height: 100%;
  padding-top: 4vw;
  padding-right: 2.9333333333vw;
  padding-bottom: 6.4vw;
  padding-left: 4.5333333333vw;
  flex-direction: column;
  background-color: #f0f0f0;
  color: #292929;
}
.popUp .innerWindow::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 15.4666666667vw;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(250, 250, 250, 0.56) 18%, #fafafa 66%);
}
.popUp .innerWindow .closeButtonWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 6.6666666667vw;
}
.popUp .innerWindow .closeButtonWrapper .closeButton {
  width: 6.6666666667vw;
  height: 6.6666666667vw;
}
.popUp .innerWindow .title {
  margin-bottom: 2.6666666667vw;
  font-size: 6.4vw;
  font-weight: 700;
}
.popUp .innerWindow .date {
  margin-bottom: 5.3333333333vw;
  font-weight: 700;
  font-size: 4.8vw;
}
.popUp .innerWindow .content {
  font-size: 4.2666666667vw;
  line-height: 8.2666666667vw;
  text-align: right;
}
.popUp .innerWindow .content a {
  color: #dc2179;
}
.popUp .innerContentWrapper {
  padding-bottom: 6.6666666667vw;
  direction: rtl;
}
.popUp .contentWrapper {
  position: relative;
  padding-right: 4.2666666667vw;
  overflow-y: auto;
  direction: ltr;
}
.popUp .contentWrapper::-webkit-scrollbar {
  width: 6px;
}
.popUp .contentWrapper::-webkit-scrollbar-thumb {
  background-color: #818b99;
  border-radius: 20px;
  background-clip: content-box;
  border: 2.5px solid transparent;
}
.popUp .contentWrapper::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #c8c8c8;
}

.visible {
  opacity: 1;
  visibility: visible;
}