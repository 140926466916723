@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  overflow: hidden;
  background-color: transparent;
}
@media only screen and (min-width: 1281px) {
  .header {
    max-width: 1280px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .header {
    background-color: #000;
  }
}
.header.isFixed {
  position: fixed;
  background-color: #0f0f0f;
}
@media only screen and (min-width: 1281px) {
  .header.isFixed {
    max-width: 100%;
  }
}
.header.isFixed .top {
  position: absolute;
}
.header.isFixed .bottom {
  border-color: rgba(0, 0, 0, 0);
}
@media only screen and (min-width: 1281px) {
  .header.isFixed .bottom {
    max-width: 1280px;
    margin: 0 auto;
  }
}
.header.isFixed .bottom::after {
  content: "";
  position: absolute;
  top: 0;
  left: -1.5625vw;
  z-index: 1;
  width: 7.8125vw;
  height: 100%;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 70%, #000 100%);
}
@media only screen and (min-width: 1281px) {
  .header.isFixed .bottom::after {
    position: fixed;
    left: -20px;
    width: 100px;
    height: 49px;
  }
}
.header.isFixed .bottom .menu {
  margin: 0.78125vw 10vw 0.78125vw 0;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
@media only screen and (min-width: 1281px) {
  .header.isFixed .bottom .menu {
    margin: 10px 128px 10px 0;
  }
}
.header.isFixed .bottom .menu ::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.header.isFixed .bottom .menu a:first-child {
  padding: 0;
}
.header.isFixed .bottom .menu a:first-child .menu-label {
  padding: 0 1.171875vw 0 1.171875vw;
}
@media only screen and (min-width: 1281px) {
  .header.isFixed .bottom .menu a:first-child .menu-label {
    padding: 0 15px 0 15px;
  }
}
.header.isFixed .bottom .menu-image {
  display: none;
}
.header.isFixed .bottom .menu-link {
  padding: 0;
}
.header.isFixed .bottom .menu-label {
  padding: 0 1.25vw 0 0.859375vw;
  margin: 0;
  white-space: nowrap;
  font-weight: 500;
}
@media only screen and (min-width: 1281px) {
  .header.isFixed .bottom .menu-label {
    padding: 0 16px 0 11px;
  }
}
.header.isFixed .bottom .menu-label:hover {
  opacity: 0.7;
}
.header.isFixed .bottom .menu a {
  border-left: 1px solid rgba(255, 255, 255, 0.6);
}
.header.isFixed .bottom .menu .swiperSlide:last-child {
  padding-left: 5.46875vw;
}
@media only screen and (min-width: 1281px) {
  .header.isFixed .bottom .menu .swiperSlide:last-child {
    padding-left: 70px;
  }
}
.header.isFixed .bottom .menu .swiperSlide:last-child a {
  border-left: 0;
}
.header-inner {
  padding: 0 1.5625vw 0;
  margin: 0 auto;
}
@media only screen and (min-width: 1281px) {
  .header-inner {
    padding: 0 20px 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-inner {
    padding: 0;
  }
}
.header-inner .top {
  display: flex;
  height: 18.90625vw;
  justify-content: space-between;
  align-items: flex-end;
  transform: translateY(0);
  transition: 0.1s;
}
@media only screen and (min-width: 1281px) {
  .header-inner .top {
    height: 242px;
  }
}
@media only screen and (max-width: 767px) {
  .header-inner .top {
    flex-direction: column-reverse;
    height: 56vw;
    justify-content: flex-end;
    align-items: center;
  }
}
.header-inner .top .logo {
  width: 30.3125vw;
  margin: 0 0vw 1.5625vw 0;
}
@media only screen and (min-width: 1281px) {
  .header-inner .top .logo {
    width: 388px;
    margin: 0 0 20px 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-inner .top .logo {
    width: 70.9333333333vw;
    margin: 3.2vw 0 2.6666666667vw 0;
  }
}
.header-inner .top .left-top-button {
  padding: 0.546875vw 1.171875vw;
  margin-bottom: 2.734375vw;
  color: #fff;
  text-decoration: none;
  font-size: 1.25vw;
  border: 2px solid #fff;
  border-radius: 50px;
  font-weight: 600;
  letter-spacing: 0.2px;
  opacity: 1;
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .header-inner .top .left-top-button {
    display: none;
  }
}
@media only screen and (min-width: 1281px) {
  .header-inner .top .left-top-button {
    padding: 7px 15px;
    margin-bottom: 35px;
    font-size: 16px;
  }
}
.header-inner .top .left-top-button:hover {
  opacity: 0.5;
}
.header-inner .top .right-part {
  display: flex;
}
.header-inner .top .right-top-button {
  padding: 0.3125vw 1.5625vw 0.390625vw 1.484375vw;
  margin-bottom: 2.734375vw;
  color: #fff;
  text-decoration: none;
  font-size: 1.25vw;
  border-radius: 50px;
  font-weight: 600;
  border: 2px solid #fff;
  opacity: 1;
  transition: 0.3s;
}
@media only screen and (min-width: 1281px) {
  .header-inner .top .right-top-button {
    padding: 1px 20px 0 19px;
    margin-bottom: 35px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .header-inner .top .right-top-button {
    padding-top: 0.8vw;
    height: 7.4666666667vw;
    font-size: 4.2666666667vw;
    padding-left: 4.5333333333vw;
    padding-right: 4.5333333333vw;
    margin-bottom: 0;
  }
}
.header-inner .top .right-top-button:hover {
  opacity: 0.5;
}
.header-inner .bottom {
  position: relative;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: rgba(255, 255, 255, 0.6);
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .header-inner .bottom {
    height: 18.4vw;
    padding-right: 0vw;
  }
}
.header-inner .bottom .stiky-logo {
  position: absolute;
  top: 0.625vw;
  width: 8.203125vw;
  height: 2.265625vw;
}
@media only screen and (min-width: 1281px) {
  .header-inner .bottom .stiky-logo {
    top: 8px;
    width: 105px;
    height: 29px;
  }
}
.header-inner .bottom .stiky-logo svg {
  width: 100%;
  height: 100%;
}
.header-inner .bottom .menu {
  display: flex;
  margin: 2.1875vw 0 1.40625vw;
}
@media only screen and (min-width: 1281px) {
  .header-inner .bottom .menu {
    margin: 27px 0 19px;
  }
}
@media only screen and (max-width: 767px) {
  .header-inner .bottom .menu {
    height: 100%;
    margin: 0;
  }
}
.header-inner .bottom .menu a:first-child {
  padding: 0;
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .header-inner .bottom .menu a:first-child {
    padding: 0 5.3333333333vw 0 0;
  }
}
.header-inner .bottom .menu a:last-child .menu-label {
  border-left: 0;
}
@media only screen and (max-width: 767px) {
  .header-inner .bottom .menu a:last-child .menu-label {
    border-left: 1px solid rgba(255, 255, 255, 0.6);
  }
}
.header-inner .bottom .menu .swiper {
  position: relative;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .header-inner .bottom .menu .swiper::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 22;
    width: 14.9333333333vw;
    height: 100%;
    background-image: linear-gradient(to right, #000 19%, rgba(0, 0, 0, 0) 100%);
  }
}
.header-inner .bottom .menu .swiper .swiperSlide {
  width: auto !important;
  height: 100%;
}
.header-inner .bottom .menu .swiper .swiperSlide:last-child .menu-label {
  border-left: none;
}
.header-inner .bottom .menu-link {
  display: flex;
  padding: 0 1.953125vw 0 0;
  text-decoration: none;
  transition: 0.3s;
}
@media only screen and (min-width: 1281px) {
  .header-inner .bottom .menu-link {
    padding: 0 25px 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-inner .bottom .menu-link {
    height: 100%;
    padding: 0 5.3333333333vw 0 0;
    align-items: center;
  }
}
.header-inner .bottom .menu-link:hover {
  opacity: 0.7;
}
.header-inner .bottom .menu-link:hover .menu-image {
  border-color: #b8b8b8;
}
.header-inner .bottom .menu-label {
  padding: 0 0.78125vw 0 2.34375vw;
  margin: 0.234375vw 0 0.46875vw;
  font-size: 1.40625vw;
  font-weight: bold;
  line-height: 1.5;
  text-shadow: 0 2px 4px #0f0f0f;
  color: white;
  border-left: 1px solid rgba(255, 255, 255, 0.6);
  white-space: pre;
  transition: 0.3s;
}
@media only screen and (min-width: 1281px) {
  .header-inner .bottom .menu-label {
    padding: 0 10px 0 30px;
    margin: 3px 0 6px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .header-inner .bottom .menu-label {
    display: flex;
    width: auto;
    height: 10.1333333333vw;
    max-width: 34.6666666667vw;
    padding: 0 0 0 4.5333333333vw;
    margin: 0;
    align-items: center;
    font-size: 4.2666666667vw;
    white-space: inherit;
    line-height: 5.0666666667vw;
    font-weight: 600;
    border-left: 1px solid rgba(255, 255, 255, 0.6);
  }
}
.header-inner .bottom .menu-image {
  min-width: 4.84375vw;
  min-height: 4.84375vw;
  border-radius: 50%;
  border: 0.390625vw solid white;
  background-size: cover;
  background-position: center;
  transition: 0.3s;
}
@media only screen and (min-width: 1281px) {
  .header-inner .bottom .menu-image {
    height: 60px;
    min-width: 61px;
    min-height: 61px;
    border: 5px solid white;
  }
}
@media only screen and (max-width: 767px) {
  .header-inner .bottom .menu-image {
    height: 10.9333333333vw;
    min-width: 10.9333333333vw;
    min-height: 10.9333333333vw;
    margin-left: 3.2vw;
    border: 2px solid white;
  }
}