@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.socials {
  position: relative;
  display: flex;
  height: 100%;
  cursor: pointer;
}
.socials .social-button {
  display: inline-block;
  width: 1.5625vw;
  margin-right: 1.09375vw;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s;
}
@media only screen and (min-width: 1281px) {
  .socials .social-button {
    width: 20px;
    margin-right: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .socials .social-button {
    width: 20px;
    margin-right: 7px;
  }
}
.socials .social-button svg {
  width: 100%;
  height: auto;
}
.socials .social-button img {
  width: 100%;
}
.socials .social-button:hover {
  opacity: 0.8;
}
.socials .social-button.is-bold {
  width: 1.796875vw;
  margin-right: 0.9375vw;
  margin-bottom: -0.5333333333vw;
}
@media only screen and (min-width: 1281px) {
  .socials .social-button.is-bold {
    width: 23px;
    margin-right: 12px;
    margin-bottom: -2px;
  }
}
@media only screen and (max-width: 767px) {
  .socials .social-button.is-bold {
    width: 26px;
    margin-right: 33px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .socials .social-button.is-bold:first-child {
    margin-right: 0;
  }
}
.socials .social-button.is-bold .icon-twitter, .socials .social-button.is-bold .icon-email {
  width: 1.875vw;
  height: 1.875vw;
}
@media only screen and (min-width: 1281px) {
  .socials .social-button.is-bold .icon-twitter, .socials .social-button.is-bold .icon-email {
    width: 24px;
    height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .socials .social-button.is-bold .icon-twitter, .socials .social-button.is-bold .icon-email {
    width: 27px;
    height: 27px;
  }
}
.socials .social-button.is-bold .icon-whatsapp, .socials .social-button.is-bold .icon-facebook {
  width: 1.796875vw;
  height: 1.796875vw;
}
@media only screen and (min-width: 1281px) {
  .socials .social-button.is-bold .icon-whatsapp, .socials .social-button.is-bold .icon-facebook {
    width: 23px;
    height: 23px;
  }
}
@media only screen and (max-width: 767px) {
  .socials .social-button.is-bold .icon-whatsapp, .socials .social-button.is-bold .icon-facebook {
    width: 26px;
    height: 26px;
  }
}
.socials .overflowWrap {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .socials .overflowWrap {
    display: flex;
    width: 37.3333333333vw;
    margin-top: -3.2vw;
    justify-content: space-around;
  }
}
.socials .overflowWrap .icon {
  width: 1.5625vw;
  height: 1.5625vw;
}
@media only screen and (min-width: 1281px) {
  .socials .overflowWrap .icon {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .socials .overflowWrap .icon {
    width: 20px;
    height: 20px;
  }
}
.socials .overflowWrap .icon-twitter, .socials .overflowWrap .icon-email {
  width: 1.640625vw;
  height: 1.640625vw;
}
@media only screen and (min-width: 1281px) {
  .socials .overflowWrap .icon-twitter, .socials .overflowWrap .icon-email {
    width: 21px;
    height: 21px;
  }
}
@media only screen and (max-width: 767px) {
  .socials .overflowWrap .icon-twitter, .socials .overflowWrap .icon-email {
    width: 21px;
    height: 21px;
  }
}
.socials .overflowWrap .icon-whatsapp {
  width: 1.5625vw;
  height: 1.5625vw;
}
@media only screen and (min-width: 1281px) {
  .socials .overflowWrap .icon-whatsapp {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .socials .overflowWrap .icon-whatsapp {
    width: 20px;
    height: 20px;
  }
}
.socials .overflowWrap .icon svg {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .socials.minified .social-links {
    padding-bottom: 5.8666666667vw;
    margin-right: 4.2666666667vw;
  }
}
.socials.minified .social-links .link {
  display: inline-block;
  margin-left: 1.953125vw;
  transition: 0.15s;
  opacity: 1;
}
@media only screen and (min-width: 1281px) {
  .socials.minified .social-links .link {
    margin-left: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .socials.minified .social-links .link {
    margin-left: 4vw;
  }
}
.socials.minified .social-links .link i {
  width: 2.34375vw;
  height: 2.421875vw;
}
@media only screen and (min-width: 1281px) {
  .socials.minified .social-links .link i {
    width: 30px;
    height: 31px;
  }
}
@media only screen and (max-width: 767px) {
  .socials.minified .social-links .link i {
    width: 8vw;
    height: 8.2666666667vw;
  }
}
.socials.minified .social-links .link i svg {
  width: 100%;
  height: 100%;
}
.socials.minified .social-links .link:hover {
  opacity: 0.5;
}