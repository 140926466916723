@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.timeline {
  position: relative;
  display: flex;
  height: 56.25vw;
  padding-top: 1.953125vw;
  padding-right: 1.5625vw;
  padding-bottom: 1.953125vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
@media only screen and (min-width: 1281px) {
  .timeline {
    height: 720px;
    padding: 0;
  }
}
@media only screen and (max-width: 767px) {
  .timeline {
    height: 100%;
    padding-top: 6.1333333333vw;
    padding-right: 0;
    padding-bottom: 8.2666666667vw;
  }
}
.timeline .content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
@media only screen and (min-width: 1281px) {
  .timeline .content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1280px;
    padding: 28px 20px 20px;
    margin: 0 auto;
  }
}
.timeline .backgroundColor {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  mix-blend-mode: hard-light;
  background-position: center;
}
.timeline .background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  opacity: 0.6;
}

.timelineItem {
  position: relative;
  z-index: 2;
}
@media only screen and (min-width: 1281px) {
  .timelineItem {
    display: block;
  }
}
.timelineItem .cover-image-wrap {
  position: absolute;
  top: 4.609375vw;
  left: 8.59375vw;
  max-width: 29.6875vw;
  max-height: 42.578125vw;
  overflow: hidden;
  transform: rotate(-13deg) translateY(-2.1875vw) translateX(2.03125vw);
  box-shadow: 0 8px 24px 30px rgba(0, 0, 0, 0.08);
}
@media only screen and (min-width: 1281px) {
  .timelineItem .cover-image-wrap {
    top: 59px;
    left: 110px;
    max-width: 380px;
    max-height: 545px;
    margin: 0;
    overflow: hidden;
    transform: rotate(-13deg) translateY(-28px) translateX(26px);
  }
}
.timelineItem .cover-image-wrap .cover-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.timelineItem .text-content {
  width: 100%;
  max-width: 44.53125vw;
}
@media only screen and (min-width: 1281px) {
  .timelineItem .text-content {
    width: 100%;
    max-width: 570px;
    margin-top: 0;
  }
}
.timelineItem .title {
  font-size: 2.1875vw;
  color: #292929;
  font-weight: 700;
}
@media only screen and (min-width: 1281px) {
  .timelineItem .title {
    font-size: 28px;
  }
}
.timelineItem .year {
  display: block;
  margin-bottom: 1.953125vw;
  font-size: 8.75vw;
  font-weight: 700;
  line-height: 8.59375vw;
}
@media only screen and (min-width: 1281px) {
  .timelineItem .year {
    margin: -3px -5px 25px 0;
    font-size: 112px;
    line-height: 110px;
  }
}
.timelineItem .content {
  max-height: 32.8125vw;
  overflow: auto;
  font-size: 1.5625vw;
  font-weight: 700;
  line-height: 2.5vw;
  color: #292929;
  text-align: right;
}
@media only screen and (min-width: 1281px) {
  .timelineItem .content {
    max-height: 420px;
    overflow: auto;
    font-size: 20px;
    line-height: 32px;
  }
}

.timeline-bottom {
  position: absolute;
  bottom: 2.34375vw;
  z-index: 3;
  display: flex;
  width: 100%;
  margin-top: 7.1875vw;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 1281px) {
  .timeline-bottom {
    bottom: 31px;
    width: 100%;
    margin-top: 92px;
  }
}
.timeline-bottom .dot {
  position: relative;
  margin-right: 0.625vw;
  overflow: hidden;
  background-color: #292929;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.5s;
}
@media only screen and (min-width: 1281px) {
  .timeline-bottom .dot {
    margin-right: 8px;
  }
}
.timeline-bottom .dot::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.timeline-bottom .dot:hover {
  transform: scale(1.5);
  opacity: 1;
}
.timeline-bottom .dot:hover::after {
  opacity: 1;
  visibility: visible;
}
.timeline-bottom .dot:first-child {
  margin-right: 0;
}
.timeline-bottom .smallDot {
  width: 8px;
  height: 8px;
  opacity: 0.5;
}
.timeline-bottom .bigDotWrapper {
  position: relative;
  margin-right: 0.625vw;
}
@media only screen and (min-width: 1281px) {
  .timeline-bottom .bigDotWrapper {
    margin-right: 8px;
  }
}
.timeline-bottom .bigDotWrapper .bigDot {
  width: 12px;
  height: 12px;
}
.timeline-bottom .bigDotWrapper .year {
  position: absolute;
  top: -3.515625vw;
  right: -1.328125vw;
  left: 0;
  margin: auto;
  font-size: 1.875vw;
  color: #292929;
  font-weight: 700;
  opacity: 0.5;
}
@media only screen and (min-width: 1281px) {
  .timeline-bottom .bigDotWrapper .year {
    top: -48px;
    right: -17px;
    font-size: 24px;
  }
}
.timeline-bottom .active {
  opacity: 1;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.19);
  background-image: linear-gradient(to right, #f045b1 0%, #f63f55 99%);
}
.timeline-bottom .yearActive {
  opacity: 1 !important;
}