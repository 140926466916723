@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.gallery {
  position: relative;
  padding: 3.984375vw 0 5.546875vw;
  margin-bottom: 3.75vw;
  overflow: hidden;
}
@media only screen and (min-width: 1281px) {
  .gallery {
    max-width: 1240px;
    padding: 51px 0 71px;
    margin: 0 auto;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .gallery {
    padding: 5.3333333333vw 0 0;
    margin-bottom: 0;
  }
}
.gallery-slider-wrapper {
  margin-right: -8.046875vw;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider-wrapper {
    margin-right: -103px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .gallery-slider-wrapper {
    margin-right: -50px;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-slider-wrapper {
    margin-right: -21.3333333333vw;
  }
}
.gallery-slider {
  min-height: 41.40625vw;
  overflow: hidden;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider {
    min-height: 530px;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-slider {
    background: none;
  }
}
.gallery-slider :global .swiper-scrollbar {
  position: absolute;
  bottom: 0.390625vw;
  left: 0.5%;
  z-index: 50;
  width: 99%;
  height: 0.625vw;
  background-color: #252525;
  border-radius: 5.5px;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider :global .swiper-scrollbar {
    bottom: 5px;
    height: 8px;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-slider :global .swiper-scrollbar {
    bottom: 5.6vw;
    left: 4.5%;
    width: 91%;
    height: 1.6vw;
    background-color: #000;
  }
}
.gallery-slider :global .swiper-scrollbar .swiper-scrollbar-drag {
  background: #dc2179;
}
@media only screen and (max-width: 767px) {
  .gallery-slider :global .swiper-scrollbar .swiper-scrollbar-drag {
    top: -0.5333333333vw;
    height: 2.6666666667vw;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-slider :global .swiper-wrapper {
    margin-bottom: 8vw;
  }
}
.gallery-slider :global .swiper-button-prev::after,
.gallery-slider :global .swiper-button-next::after {
  display: none;
}
.gallery-slider :global .swiper-button-next.swiper-button-disabled,
.gallery-slider :global .swiper-button-prev.swiper-button-disabled {
  opacity: 0.85;
}
.gallery-slider :global .swiper-button-next,
.gallery-slider :global .swiper-button-prev {
  top: 47.5%;
  width: 8.59375vw;
  height: 8.59375vw;
  background-size: cover;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider :global .swiper-button-next,
  .gallery-slider :global .swiper-button-prev {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-slider :global .swiper-button-next,
  .gallery-slider :global .swiper-button-prev {
    top: 46.5%;
    width: 16vw;
    height: 16vw;
  }
}
.gallery-slider :global .swiper-button-next {
  right: auto !important;
  left: 0 !important;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='66px' height='66px' viewBox='0 0 66 66' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-1' x='0' y='0' width='30' height='30' rx='7.05882353'%3E%3C/rect%3E%3Cfilter x='-96.7%25' y='-83.3%25' width='293.3%25' height='293.3%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeMorphology radius='3' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'%3E%3C/feMorphology%3E%3CfeOffset dx='0' dy='4' in='shadowSpreadOuter1' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='6' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'%3E%3C/feComposite%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-2' transform='translate(18.000000, 14.000000)'%3E%3Cg id='Rectangle'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='0.7' fill='%23000000' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3Cpath d='M11.6483082,9.41176471 L12.9336376,10.6209442 L9.71258824,14.0427647 L23.6292225,14.1907732 L23.6105922,15.9553807 L9.80558824,15.8087647 L12.9230025,19.0101306 L11.6589433,20.2415286 L6.47058824,14.9155597 L11.6483082,9.41176471 Z' id='Combined-Shape' fill='%23FFFFFF' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}
.gallery-slider :global .swiper-button-prev {
  right: 7.8125vw !important;
  left: auto !important;
  background-image: url("data:image/svg+xml,%3Csvg width='66' height='66' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cfilter x='-96.7%25' y='-83.3%25' width='293.3%25' height='293.3%25' filterUnits='objectBoundingBox' id='a'%3E%3CfeMorphology radius='3' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'/%3E%3CfeOffset dy='4' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur stdDeviation='6' in='shadowOffsetOuter1' result='shadowBlurOuter1'/%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' in='shadowBlurOuter1'/%3E%3C/filter%3E%3Crect id='b' x='0' y='0' width='30' height='30' rx='7.059'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='matrix(-1 0 0 1 48 14)' fill='%23000'%3E%3Cuse filter='url(%23a)' xlink:href='%23b'/%3E%3Cuse fill-opacity='.7' xlink:href='%23b'/%3E%3C/g%3E%3Cpath d='m36.352 23.412-1.286 1.209 3.221 3.422-13.916.148.018 1.764 13.805-.146-3.117 3.201 1.264 1.232 5.188-5.326-5.177-5.504z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider :global .swiper-button-prev {
    right: 100px !important;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-slider :global .swiper-button-prev {
    right: 21.3333333333vw !important;
  }
}
.gallery-slider :global .swiper-slide-active:hover {
  z-index: 10 !important;
  cursor: pointer;
}
.gallery-slider :global .swiper-slide-active:hover :local .slide-hover {
  opacity: 1;
}
.gallery-slider .slide {
  position: relative;
  width: 29.140625vw;
  height: 41.484375vw;
  box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.9);
}
@media only screen and (min-width: 1281px) {
  .gallery-slider .slide {
    width: 373px;
    height: 531px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .gallery-slider .slide {
    width: 285px;
    height: 430px;
    box-shadow: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .gallery-slider .slide {
    width: 315px;
    height: 465px;
    box-shadow: 0 2px 80px 0 rgba(0, 0, 0, 0.9);
  }
}
@media only screen and (max-width: 767px) {
  .gallery-slider .slide {
    width: 59.2vw;
    height: 83.7333333333vw;
  }
}
.gallery-slider .slide .cover-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border: 1.015625vw solid white;
  background-color: white;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider .slide .cover-wrap {
    border: 13px solid white;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .gallery-slider .slide .cover-wrap {
    border: 13px solid white;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-slider .slide .cover-wrap {
    border: 2.1333333333vw solid white;
  }
}
.gallery-slider .slide .cover-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery-slider .slide-hover {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20.9375vw;
  opacity: 1;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 13%, rgba(0, 0, 0, 0.94) 63%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider .slide-hover {
    height: 268px;
  }
}
.gallery-slider .slide-hover-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 2.890625vw 1.71875vw;
  color: white;
  text-align: center;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider .slide-hover-content {
    padding: 0 37px 22px;
  }
}
.gallery-slider .slide-hover-content .stide-title {
  padding-bottom: 0.9375vw;
  font-size: 1.875vw;
  line-height: 1.21;
  font-weight: 600;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider .slide-hover-content .stide-title {
    padding-bottom: 12px;
    font-size: 24px;
  }
}
.gallery-slider .slide-hover-content .slide-subtitle {
  padding-bottom: 1.09375vw;
  font-size: 1.25vw;
  line-height: 1.13;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider .slide-hover-content .slide-subtitle {
    padding-bottom: 14px;
    font-size: 16px;
  }
}
.gallery-slider .slide-hover-content .socials {
  display: flex;
  padding-top: 1.25vw;
  text-align: right;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}
@media only screen and (min-width: 1281px) {
  .gallery-slider .slide-hover-content .socials {
    padding-top: 16px;
  }
}
.gallery-slider .slide-hover-content .socials .socials-text {
  font-size: 1.25vw;
}
@media only screen and (min-width: 1281px) {
  .gallery-slider .slide-hover-content .socials .socials-text {
    font-size: 16px;
  }
}
.gallery .popup-slider-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 1.015625vw 0 0.625vw;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, #141414 3%, #282828 97%);
}
@media only screen and (min-width: 1281px) {
  .gallery .popup-slider-wrapper {
    padding: 13px 0 8px;
  }
}
@media only screen and (max-width: 767px) {
  .gallery .popup-slider-wrapper {
    position: fixed;
    z-index: 100;
    background-image: linear-gradient(to bottom, #0f0f0f 17%, rgba(24, 24, 24, 0.92) 92%);
  }
}
@media only screen and (max-width: 767px) {
  .gallery .popup-slider-wrapper.is-scrolling .popup-slide .cover-wrap {
    width: 52.2666666667vw;
    border: 1.8666666667vw solid white;
  }
  .gallery .popup-slider-wrapper.is-scrolling .popup-navigation .popup-button-next-arrow,
  .gallery .popup-slider-wrapper.is-scrolling .popup-navigation .popup-button-prev-arrow {
    top: 30%;
    width: 17.0666666667vw;
    height: 17.0666666667vw;
  }
}
.gallery .popup-slider-wrapper :global .swiper-container {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .gallery .popup-slider-wrapper :global .swiper-container {
    height: auto;
  }
}
.gallery .popup-slider-wrapper .close-icon {
  position: absolute;
  top: 1.953125vw;
  right: 1.5625vw;
  z-index: 10;
  opacity: 1;
  transition: 0.2s;
}
@media only screen and (min-width: 1281px) {
  .gallery .popup-slider-wrapper .close-icon {
    top: 25px;
    right: 20px;
  }
}
.gallery .popup-slider-wrapper .close-icon:hover {
  cursor: pointer;
  opacity: 0.8;
}
.gallery .popup-slider-wrapper .popup-navigation {
  position: absolute;
  top: 1.40625vw;
  left: 50%;
  z-index: 10;
  display: flex;
  width: 26.5625vw;
  justify-content: space-between;
  transform: translate(-50%, 0);
  font-size: 1.71875vw;
  font-weight: 600;
  line-height: 1.41;
  color: white;
}
@media only screen and (min-width: 1281px) {
  .gallery .popup-slider-wrapper .popup-navigation {
    top: 18px;
    width: 340px;
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .gallery .popup-slider-wrapper .popup-navigation {
    position: static;
    width: 100%;
    transform: none;
  }
}
.gallery .popup-slider-wrapper .popup-navigation .popup-button-next,
.gallery .popup-slider-wrapper .popup-navigation .popup-button-prev {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-next,
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-prev {
    display: block;
  }
}
.gallery .popup-slider-wrapper .popup-navigation .popup-button-next .nav-label,
.gallery .popup-slider-wrapper .popup-navigation .popup-button-prev .nav-label {
  padding-bottom: 0.78125vw;
}
@media only screen and (min-width: 1281px) {
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-next .nav-label,
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-prev .nav-label {
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-next .nav-label,
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-prev .nav-label {
    display: none;
  }
}
.gallery .popup-slider-wrapper .popup-navigation .popup-button-next .nav-label {
  margin-left: -25px;
}
.gallery .popup-slider-wrapper .popup-navigation .popup-button-prev .nav-label {
  margin-right: -25px;
}
.gallery .popup-slider-wrapper .popup-navigation .popup-button-next-arrow,
.gallery .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
  z-index: 100;
  width: 8.59375vw;
  height: 8.59375vw;
  background-size: cover;
  opacity: 1;
  transition: 0.2s;
}
@media only screen and (min-width: 1025px) {
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-next-arrow,
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
    position: static;
  }
}
@media only screen and (min-width: 1281px) {
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-next-arrow,
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (max-width: 767px) {
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-next-arrow,
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
    position: absolute;
    top: 43%;
    width: 17.0666666667vw;
    height: 17.0666666667vw;
    transform: translateY(-50%);
    transition: 0.5s;
  }
}
.gallery .popup-slider-wrapper .popup-navigation .popup-button-next-arrow:hover,
.gallery .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow:hover {
  cursor: pointer;
  opacity: 0.8;
}
.gallery .popup-slider-wrapper .popup-navigation .popup-button-next-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='66px' height='66px' viewBox='0 0 66 66' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Crect id='path-1' x='0' y='0' width='30' height='30' rx='7.05882353'%3E%3C/rect%3E%3Cfilter x='-96.7%25' y='-83.3%25' width='293.3%25' height='293.3%25' filterUnits='objectBoundingBox' id='filter-2'%3E%3CfeMorphology radius='3' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'%3E%3C/feMorphology%3E%3CfeOffset dx='0' dy='4' in='shadowSpreadOuter1' result='shadowOffsetOuter1'%3E%3C/feOffset%3E%3CfeGaussianBlur stdDeviation='6' in='shadowOffsetOuter1' result='shadowBlurOuter1'%3E%3C/feGaussianBlur%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'%3E%3C/feComposite%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'%3E%3C/feColorMatrix%3E%3C/filter%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-2' transform='translate(18.000000, 14.000000)'%3E%3Cg id='Rectangle'%3E%3Cuse fill='black' fill-opacity='1' filter='url(%23filter-2)' xlink:href='%23path-1'%3E%3C/use%3E%3Cuse fill-opacity='1' fill='rgba(0, 0, 0, 0.7)' fill-rule='evenodd' xlink:href='%23path-1'%3E%3C/use%3E%3C/g%3E%3Cpath d='M11.6483082,9.41176471 L12.9336376,10.6209442 L9.71258824,14.0427647 L23.6292225,14.1907732 L23.6105922,15.9553807 L9.80558824,15.8087647 L12.9230025,19.0101306 L11.6589433,20.2415286 L6.47058824,14.9155597 L11.6483082,9.41176471 Z' id='Combined-Shape' fill='%23FFFFFF' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}
@media only screen and (max-width: 767px) {
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-next-arrow {
    left: -2px;
  }
}
.gallery .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
  background-image: url("data:image/svg+xml,%3Csvg width='66' height='66' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cfilter x='-96.7%25' y='-83.3%25' width='293.3%25' height='293.3%25' filterUnits='objectBoundingBox' id='a'%3E%3CfeMorphology radius='3' operator='dilate' in='SourceAlpha' result='shadowSpreadOuter1'/%3E%3CfeOffset dy='4' in='shadowSpreadOuter1' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur stdDeviation='6' in='shadowOffsetOuter1' result='shadowBlurOuter1'/%3E%3CfeComposite in='shadowBlurOuter1' in2='SourceAlpha' operator='out' result='shadowBlurOuter1'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0' in='shadowBlurOuter1'/%3E%3C/filter%3E%3Crect id='b' x='0' y='0' width='30' height='30' rx='7.059'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='matrix(-1 0 0 1 48 14)' fill='rgba(0, 0, 0, 0.7)'%3E%3Cuse filter='url(%23a)' xlink:href='%23b'/%3E%3Cuse fill-opacity='1' xlink:href='%23b'/%3E%3C/g%3E%3Cpath d='m36.352 23.412-1.286 1.209 3.221 3.422-13.916.148.018 1.764 13.805-.146-3.117 3.201 1.264 1.232 5.188-5.326-5.177-5.504z' fill='%23FFF' fill-rule='nonzero'/%3E%3C/g%3E%3C/svg%3E ") !important;
}
@media only screen and (max-width: 767px) {
  .gallery .popup-slider-wrapper .popup-navigation .popup-button-prev-arrow {
    right: -2px;
  }
}

@keyframes mover-prev {
  0% {
    transform: translate3d(-3.984375vw, 0, 0);
  }
  80% {
    transform: translate3d(600px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}