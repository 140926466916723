@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.popUp {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 60.9375vw;
  padding-top: 0.859375vw;
  padding-right: 1.5625vw;
  padding-bottom: 0.859375vw;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
}
@media only screen and (min-width: 1281px) {
  .popUp {
    height: 780px;
    padding-top: 11px;
    padding-right: 40px;
    padding-bottom: 11px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp {
    position: fixed;
    height: 100%;
    padding-top: 8.8vw;
    padding-right: 6.6666666667vw;
    padding-bottom: 4.5333333333vw;
    padding-left: 6.6666666667vw;
  }
}
.popUp-inner {
  position: relative;
  width: 100vw;
  height: 100%;
  margin: 0 auto;
}
@media only screen and (min-width: 1281px) {
  .popUp-inner {
    width: 1280px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp-inner {
    width: 100%;
  }
}
.popUp .overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.41);
}
.popUp .innerWindow {
  position: relative;
  z-index: 10;
  display: flex;
  width: 48.4375vw;
  height: 100%;
  padding-top: 2.578125vw;
  padding-right: 2.265625vw;
  padding-bottom: 3.125vw;
  padding-left: 4.6875vw;
  flex-direction: column;
  background-color: #f0f0f0;
  color: #292929;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow {
    width: 620px;
    padding-top: 33px;
    padding-right: 29px;
    padding-bottom: 40px;
    padding-left: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow {
    width: 100%;
    padding-top: 4vw;
    padding-right: 2.9333333333vw;
    padding-bottom: 6.4vw;
    padding-left: 4.5333333333vw;
  }
  .popUp .innerWindow::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 15.4666666667vw;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(250, 250, 250, 0.56) 18%, #fafafa 66%);
  }
}
.popUp .innerWindow .innerContentWrapper {
  direction: rtl;
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .innerContentWrapper {
    padding-bottom: 6.6666666667vw;
  }
}
.popUp .innerWindow .contentWrapper {
  padding-right: 2.421875vw;
  overflow-y: auto;
  direction: ltr;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .contentWrapper {
    padding-right: 31px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .contentWrapper {
    position: relative;
    padding-right: 4.2666666667vw;
  }
}
.popUp .innerWindow .contentWrapper::-webkit-scrollbar {
  width: 0.46875vw;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .contentWrapper::-webkit-scrollbar {
    width: 6px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .contentWrapper::-webkit-scrollbar {
    width: 6px;
  }
}
.popUp .innerWindow .contentWrapper::-webkit-scrollbar-thumb {
  background-color: #818b99;
  border: 0.15625vw solid transparent;
  border-radius: 20px;
  background-clip: content-box;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .contentWrapper::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .contentWrapper::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
  }
}
.popUp .innerWindow .contentWrapper::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #c8c8c8;
}
.popUp .innerWindow .closeButtonWrapper {
  display: flex;
  width: 100%;
  margin-right: -0.703125vw;
  margin-bottom: 2.1875vw;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .closeButtonWrapper {
    margin-right: -9px;
    margin-bottom: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .closeButtonWrapper {
    margin-right: 0;
    margin-bottom: 6.6666666667vw;
  }
}
.popUp .innerWindow .closeButtonWrapper .closeButton {
  width: 1.953125vw;
  height: 1.953125vw;
  cursor: pointer;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .closeButtonWrapper .closeButton {
    width: 25px;
    height: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .closeButtonWrapper .closeButton {
    width: 5.3333333333vw;
    height: 5.3333333333vw;
  }
}
.popUp .innerWindow .topTitle {
  margin-bottom: 1.25vw;
  font-weight: 700;
  font-size: 2.65625vw;
  line-height: 2.65625vw;
  color: #1e1e1e;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .topTitle {
    margin-bottom: 16px;
    font-size: 34px;
    line-height: 34px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .topTitle {
    font-size: 6.4vw;
    line-height: normal;
  }
}
.popUp .innerWindow .title {
  font-size: 1.875vw;
  font-weight: 700;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .title {
    font-size: 4.8vw;
  }
}
.popUp .innerWindow .celebDescription {
  margin-bottom: 1.5625vw;
  font-size: 1.5625vw;
  line-height: 2.421875vw;
  text-align: right;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .celebDescription {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 31px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .celebDescription {
    margin-bottom: 6.6666666667vw;
    font-size: 4.2666666667vw;
    line-height: 6.6666666667vw;
  }
}
.popUp .innerWindow .titleWrapper {
  display: flex;
  margin-bottom: 1.640625vw;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .titleWrapper {
    margin-bottom: 21px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .titleWrapper {
    margin-bottom: 2.8533333333vw;
  }
}
.popUp .innerWindow .titleWrapper p {
  font-size: 1.875vw;
  font-weight: 700;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .titleWrapper p {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .titleWrapper p {
    font-size: 4.8vw;
  }
}
.popUp .innerWindow .titleWrapper .heart {
  width: 2.5vw;
  height: 2.03125vw;
  margin-left: 0.9375vw;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .titleWrapper .heart {
    width: 32px;
    height: 26px;
    margin-left: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .titleWrapper .heart {
    position: relative;
    top: -0.2666666667vw;
    width: 7.2vw;
    height: 5.8666666667vw;
    margin-left: 2.1333333333vw;
  }
}
.popUp .innerWindow .content {
  font-size: 1.5625vw;
  line-height: 2.421875vw;
  text-align: right;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow .content {
    font-size: 20px;
    line-height: 31px;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow .content {
    font-size: 4.2666666667vw;
    line-height: 8.2666666667vw;
  }
}
.popUp .innerWindow .content a {
  color: #dc2179;
}
.popUp .innerWindow span {
  background-color: transparent !important;
  font-family: "Assistant", sans-serif !important;
  font-size: 1.5625vw !important;
}
@media only screen and (min-width: 1281px) {
  .popUp .innerWindow span {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 767px) {
  .popUp .innerWindow span {
    font-size: 4.2666666667vw !important;
  }
}

.visible {
  opacity: 1;
  visibility: visible;
}