@charset "UTF-8";
/**
	1. FIXME: webpack doesn't alert calling undefined function

	2. If you are not strong with scss maps, take a look at
	{ @link https://www.sitepoint.com/extra-map-functions-sass/}
*/
/**
	Get value in SCSS $map
	@usage — padding-top: get($popup, padding-top);
 */
/**
	Get value in nested SCSS $map
	@usage — map-deep-get($map, $level1, $level2)
 */
/**
	Math.pow
 */
/**
	Factorial
 */
/**
	Math.PI
 */
/**
	Get angle in radians
 */
/**
	Math.sin
 */
/**
	Math.cos
 */
/**
	Math.tan
 */
/**
	Random number in range
 */
/**
	Convert px to rem
	@usage — calculateRem(16px) => font-size: 1rem;
 */
/*
	Remove the unit of a number
	@param {Number} $number - Number to remove unit from
	@return {Number} - Unitless number
 */
/*
	Common media query mixin.

	Usage:
	.element {
		@include screen('tablet') {
			width: 90%;
		}
		@include screen('desktop') {
			width: 85%;
		}
		@include screen('(min-width: 999px)') {
			width: 1280px;
		}
	}
 */
/*
	Common typography mixin.

	It allows to assign only passed values.

	Usage:
	@include font(
		$family: Value,      [mono, sans, serif] => $font-family
		$size: Value,        [text-giga, text-huge, text-regular, ...] => $font-size
		$weight: Value,      [bold, normal] => $font-weight
		$transform: Value,   [capitalize, ...] => $font-transform
		$color: String,      ['disabled', 'default' ,'error', 'warning'] => $font-colors
	);
 */
/*
	Transfer each map pair of KEY: VALUE to CSS-PROPERTY: CSS-PROPERTY-VALUE;

	Usage:
	@include mapToProps($someMap); ==> $someMap: ( font-size: 1px, line-height: 1.5 );
 */
/*
	@usage —
	.menu li {
		@include relative;
	}
	.sub-menu {
		@include absolute(top 100% left 0);
	}
	.sticky-bar {
		@include fixed(top 0 left 0);
	}
*/
/* *
* - Grid system (alternative)
*
* - Two types:
    - 1. grid with floats (default)
    - 2. inline - grid with inline-blocks
*
* - @{link - http://thesassway.com/intermediate/simple-grid-mixins}
* */
.cover-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 1.015625vw 0 2.890625vw;
  justify-content: space-between;
  background-image: linear-gradient(to bottom, #0f0f0f 17%, rgba(24, 24, 24, 0.92) 92%);
}
@media only screen and (min-width: 1281px) {
  .cover-popup {
    padding: 13px 0 37px;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    background-image: linear-gradient(to bottom, #0f0f0f 17%, rgba(24, 24, 24, 0.92) 92%);
  }
}
.cover-popup .close-icon {
  position: absolute;
  top: 1.953125vw;
  right: 1.5625vw;
  z-index: 10;
  opacity: 1;
  transition: 0.2s;
}
@media only screen and (min-width: 1281px) {
  .cover-popup .close-icon {
    top: 25px;
    right: 20px;
  }
}
.cover-popup .close-icon:hover {
  cursor: pointer;
  opacity: 0.8;
}

.item-container {
  display: flex;
  height: 100%;
  max-width: 100vw;
  margin: 0 auto;
  align-items: center;
}
@media only screen and (min-width: 1281px) {
  .item-container {
    max-width: 1240px;
  }
}
@media only screen and (max-width: 767px) {
  .item-container {
    height: auto;
    max-width: 100%;
  }
}
@media only screen and (min-width: 1025px) {
  .item-container.slide .cover-popup-content {
    padding: 93px 56px 10px 57px;
  }
  .item-container.slide .cover-popup-content.full-height {
    max-height: 100%;
  }
  .item-container.slide .cover-popup-content .content {
    width: 500px;
    padding-top: 7.8125vw;
  }
  .item-container.slide .cover-popup-content .content .title {
    padding-bottom: 11px;
  }
  .item-container.slide .cover-popup-content .content .date {
    padding-bottom: 30px;
  }
}

.cover-popup-content {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 56.25vw;
  padding: 1.875vw 12.5vw 0.78125vw 12.109375vw;
  justify-content: space-between;
  align-self: center;
  color: white;
}
@media only screen and (min-width: 1281px) {
  .cover-popup-content {
    width: 100%;
    max-height: 720px;
    padding: 24px 0 10px 0;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content {
    width: 100%;
    max-height: 100%;
    padding: 15.4666666667vw 8vw 5.3333333333vw;
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content.is-scrolling .cover-wrap {
    width: 52.2666666667vw;
  }
  .cover-popup-content.is-scrolling .cover-image {
    border: 1.8666666667vw solid white;
  }
}
.cover-popup-content .cover-wrap {
  position: relative;
  display: flex;
  height: 100%;
  max-width: 50%;
  max-height: 100%;
  justify-content: flex-end;
  align-items: self-end;
}
@media only screen and (min-width: 1281px) {
  .cover-popup-content .cover-wrap {
    width: auto;
    height: 100%;
    max-width: 47%;
    max-height: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .cover-wrap {
    width: 100%;
    height: 100%;
    max-width: 78.6666666667vw;
    margin: 0 auto;
    transition: 0.5s;
  }
}
.cover-popup-content .cover-wrap .cover-image {
  width: inherit;
  height: inherit;
  border: 1.171875vw solid white;
}
@media only screen and (min-width: 1281px) {
  .cover-popup-content .cover-wrap .cover-image {
    border: 15px solid white;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .cover-wrap .cover-image {
    border: 2.6666666667vw solid white;
  }
}
.cover-popup-content .cover-wrap img {
  width: auto;
  height: 100%;
  object-fit: contain;
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .cover-wrap img {
    width: 100%;
  }
}
.cover-popup-content .cover-wrap .credits {
  position: absolute;
  right: 1px;
  bottom: 0;
  font-size: 1.015625vw;
  color: black;
}
@media only screen and (min-width: 1281px) {
  .cover-popup-content .cover-wrap .credits {
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .cover-wrap .credits {
    font-size: 2.6666666667vw;
  }
}
.cover-popup-content .content {
  display: flex;
  width: 30.46875vw;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
@media only screen and (min-width: 1281px) {
  .cover-popup-content .content {
    width: 390px;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .content {
    width: 100%;
    padding: 22px 10px 20px;
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .content .top-text-wrapper {
    display: flex;
    padding-bottom: 22px;
    justify-content: space-between;
  }
}
.cover-popup-content .content .title {
  font-size: 3.4375vw;
  line-height: 1.2;
  font-weight: bold;
}
@media only screen and (min-width: 1281px) {
  .cover-popup-content .content .title {
    font-size: 44px;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .content .title {
    font-size: 6.4vw;
  }
}
.cover-popup-content .content .date {
  padding-bottom: 1.640625vw;
  font-size: 1.71875vw;
  font-weight: 600;
}
@media only screen and (min-width: 1281px) {
  .cover-popup-content .content .date {
    padding-bottom: 21px;
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .content .date {
    padding-bottom: 0;
    font-size: 6.4vw;
    line-height: 1.2;
    font-weight: normal;
  }
}
.cover-popup-content .content .text-wrapper {
  height: 25vw;
  overflow: hidden;
}
@media only screen and (min-width: 1281px) {
  .cover-popup-content .content .text-wrapper {
    height: 320px;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .content .text-wrapper {
    width: 100%;
    height: calc(100vh - 133.3333333333vw);
  }
}
.cover-popup-content .content .text-wrapper .text {
  height: 100%;
  overflow-y: auto;
  font-size: 1.71875vw;
  font-weight: 600;
  line-height: 1.41;
}
@media only screen and (min-width: 1281px) {
  .cover-popup-content .content .text-wrapper .text {
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .content .text-wrapper .text {
    font-size: 4.2666666667vw;
    font-weight: normal;
    line-height: 1.56;
    letter-spacing: 0.2px;
  }
}
.cover-popup-content .socials {
  height: auto;
  padding: 2.96875vw 4.609375vw 0;
}
@media only screen and (min-width: 1281px) {
  .cover-popup-content .socials {
    padding: 38px 59px 0;
  }
}
@media only screen and (max-width: 767px) {
  .cover-popup-content .socials {
    padding: 0 0 3.4666666667vw 1.8666666667vw;
    margin: 0 auto;
  }
}